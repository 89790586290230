import { verify } from "web3-token";

import BNBImage from "../assets/images/coins/bnb.png";
import BUSDImage from "../assets/images/coins/busd.png";
import MATICImage from "../assets/images/coins/matic.png";
import USDTImage from "../assets/images/coins/usdt.png";
import ETHImage from "../assets/images/coins/eth.png";

export const authGuard = (isUserLoggedIn: boolean, navigate: any) => {
  if (!isUserLoggedIn) {
    navigate("/wallet-connect");
  }
};

export const sortByProperty = (property) => {
  return function (a: any, b: any) {
    var sortStatus = 0,
      aProp = a[property].toLowerCase(),
      bProp = b[property].toLowerCase();
    if (aProp < bProp) {
      sortStatus = -1;
    } else if (aProp > bProp) {
      sortStatus = 1;
    }
    return sortStatus;
  };
};

export const nftSizes = ["Small", "Medium", "Large"];

export const nftCategories = [
  "Art",
  "Music",
  "Domain Names",
  "Virtual World",
  "Trading Cards",
  "Sports",
  "Utility",
];

export const networks = [
  // "Ethereum Mainnet",
  // "Binance Smart Chain",
  // "Polygon Mainnet",
  "Ethereum Testnet",
  "BSC Testnet",
  "Polygon Testnet",
];

export const bscCoinTypes = [
  {
    name: "BNB",
    img: BNBImage,
    type: "crypt-bsc-bnb",
  },
  { name: "BUSD", img: BUSDImage, type: "crypt-bsc-busd" },
];
export const maticCoinTypes = [
  {
    name: "MATIC",
    img: MATICImage,
    type: "crypt-polygon-matic",
  },
  {
    name: "USDT",
    img: USDTImage,
    type: "crypt-polygon-usdt",
  },
];

export const ethCoinTypes = [
  { name: "ETH", img: ETHImage, type: "crypt-eth-eth" },
  { name: "USDT", img: USDTImage, type: "crypt-eth-usdt" },
];

export const coinTypes = [
  {
    name: "BNB",
    img: BNBImage,
  },
  {
    name: "BUSD",
    img: BUSDImage,
  },
  {
    name: "MATIC",
    img: MATICImage,
  },
  {
    name: "USDT",
    img: USDTImage,
  },
];

export const authenticateToken = (token: string | undefined) => {
  try {
    const { address, body } = verify(token);
    return { address, body };
  } catch (error) {
    throw new Error("Unauthorized");
  }
};
