import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const EditNFTModal = ({
  show,
  onHide,
  method,
  setMethod,
  price,
  setPrice,
  minBid,
  setMinBid,
  startingDate,
  setStartingDate,
  endingDate,
  setEndingDate,
  putOnSale,
  setTriggerSingleRefetch,
}) => {
  const [isInValidInput, setIsInValidInput] = useState(false);

  const btnAction = async (e) => {
    e.preventDefault();
    console.log('clicking on the button');
    setTriggerSingleRefetch(true);
    setIsInValidInput(false);
    const validInput = validateInput();
    if (!validInput) {
      setIsInValidInput(true);
      return;
    }

    await putOnSale();
    closeModal();
    setTriggerSingleRefetch(false);
  };

  const closeModal = () => {
    setIsInValidInput(false);
    onHide();
  };

  const validateInput = () => {
    let validInput = true;

    switch (method) {
      case "Time Auctions": {
        if (!minBid || !startingDate || !endingDate) {
          validInput = false;
        }
        break;
      }
      case "Open For Bids": {
        if (!minBid || !endingDate) {
          validInput = false;
        }
        break;
      }
      case "Fixed Price":
        if (!price) {
          validInput = false;
        }
        break;
      default: {
        break;
      }
    }

    return validInput;
  };

  const changeNFTType = (type) => {
    setMethod(type);
  }

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header closeButton></Modal.Header>

      <Container className="create-item">
        <div className="flat-tabs tab-create-item">
          <h4 className="title-create-item">Select method</h4>
          <Tabs>
            <TabList>
              <Tab onClick={() => changeNFTType("Fixed Price")}>
                <span className="icon-fl-tag"></span>Fixed Price
              </Tab>
              {/* <Tab onClick={() => changeNFTType("Time Auctions")}>
                <span className="icon-fl-clock"></span>Time Auctions
              </Tab>
              <Tab onClick={() => changeNFTType("Open For Bids")}>
                <span className="icon-fl-icon-22"></span>Open For Bids
              </Tab> */}
            </TabList>
            <TabPanel>
              <form action="#">
                <>
                  <h4 className="title-create-item">Price</h4>
                  <input
                    type="text"
                    placeholder="Enter price for one item (ETH)"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  {isInValidInput && (
                    <div
                      style={{
                        marginTop: "3rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorMsg>Please fill in all the fields</ErrorMsg>
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: "3rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button onClick={btnAction}>Confirm</button>
                  </div>
                </>
              </form>
            </TabPanel>
            <TabPanel>
              <form action="#">
                <>
                  <h4 className="title-create-item">Minimum bid</h4>
                  <input
                    value={minBid}
                    onChange={(e) => setMinBid(e.target.value)}
                    type="text"
                    placeholder="enter minimum bid"
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="title-create-item">
                        Starting date
                      </h5>
                      <input
                        type="datetime-local"
                        name="bid_starting_date"
                        id="bid_starting_date"
                        className="form-control"
                        min="1997-01-01"
                        value={startingDate}
                        onChange={(e) =>
                          setStartingDate(e.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <h4 className="title-create-item">
                        Expiration date
                      </h4>
                      <input
                        type="datetime-local"
                        name="bid_expiration_date"
                        id="bid_expiration_date"
                        className="form-control"
                        value={endingDate}
                        onChange={(e) =>
                          setEndingDate(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {isInValidInput && (
                    <div
                      style={{
                        marginTop: "3rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorMsg>Please fill in all the fields</ErrorMsg>
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: "3rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button onClick={btnAction}>Confirm</button>
                  </div>
                </>
              </form>
            </TabPanel>
            <TabPanel>
              <form action="#">
                <>
                  <h4 className="title-create-item">Minimum bid</h4>
                  <input
                    value={minBid}
                    onChange={(e) => setMinBid(e.target.value)}
                    type="text"
                    placeholder="enter minimum bid"
                  />

                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="title-create-item">
                        Expiration date
                      </h4>
                      <input
                        type="datetime-local"
                        name="bid_expiration_date"
                        id="bid_expiration_date2"
                        className="form-control"
                        value={endingDate}
                        onChange={(e) =>
                          setEndingDate(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {isInValidInput && (
                    <div
                      style={{
                        marginTop: "3rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorMsg>Please fill in all the fields</ErrorMsg>
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: "3rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button onClick={btnAction}>Confirm</button>
                  </div>
                </>
              </form>
            </TabPanel>
          </Tabs>
        </div>
      </Container>
    </Modal>
  );
};

export default EditNFTModal;

const ErrorMsg = styled.p`
  color: red;
  text-align: center;
`;

const Container = styled.div`
  padding: 20px 50px;

  button {
    background-color: #f7f7f7 !important;
    color: #5142FC !important;
    z-index: 999 !important;
  }
`;