import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import styled from "styled-components";
import TopSellerItem from "../layouts/home/TopSellerItem";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const SearchTabs = (props) => {
  const {
    users,
    collections,
    nfts,
    isLoadingUsers,
    isLoadingCollections,
    isLoadingNfts,
  } = props;
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  return (
    <ContainerStyled>
      <StyledBox sx={{ width: "100%" }}>
        <Tabs
          className="tabs"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Collections" className="tab" />
          <Tab label="Nfts" className="tab" />
          <Tab label="Users" className="tab" />
        </Tabs>
      </StyledBox>

      <TabPanel value={value} index={0}>
        {isLoadingCollections ? (
          <p>Loading...</p>
        ) : (
          <div className="row">
            {collections.map((collection, index) => (
              <TopSellerItem
                isCollection={true}
                key={index}
                item={{
                  id: collection._id,
                  profileImage: collection.logo,
                  displayName: collection.name,
                  nftCount: collection.nfts.length
                }}
              />
            ))}
            {collections.length === 0 && <p>No collections found</p>}
          </div>
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {isLoadingNfts ? (
          <p>Loading...</p>
        ) : (
          <div className="row">
            {nfts.map((user, index) => (
              <TopSellerItem
                isNft={true}
                key={index}
                item={{
                  id: user._id,
                  profileImage: user.file,
                  displayName: user.title,
                  price: user.price,
                  putOnMarket: user.putOnMarket
                }}
              />
            ))}
            {nfts.length === 0 && <p>No nfts found</p>}
          </div>
        )}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {isLoadingUsers ? (
          <p>Loading...</p>
        ) : (
          <div className="row">
            {users.map((user, index) => (
              <TopSellerItem
                isUser={true}
                key={index}
                item={{
                  id: user._id,
                  profileImage: user.profileImage,
                  displayName: user.displayName,
                  address: user.address.toLowerCase(),
                  isVerified: user.isVerified,
                }}
              />
            ))}
            {users.length === 0 && <p>No user found</p>}
          </div>
        )}
      </TabPanel>
    </ContainerStyled>
  );
};

export default SearchTabs;

const ContainerStyled = styled.div``;

const StyledBox = styled(Box)`
  .tabs {
    border-bottom: 1px solid #4f4f4f;
    display: grid;
  }
  .tab {
    color: white;
    font-size: 1.5rem;
    border: none;
    font-weight: bold;
    text-transform: Capitalize;
  }
  .MuiTabs-indicator {
    background-color: white !important;
  }
`;
