const env = process.env.REACT_APP_NODE_ENV || "prod";
const baseUrl = `https://akuqrnqlf4.execute-api.us-east-1.amazonaws.com/${env}`;

export const IPFS = `https://ipfs.infura.io:5001/api/v0`;
export const Apis = {
  USER: `${baseUrl}/user`,
  NFT: `${baseUrl}/nft`,
  COLLECTIONS: `${baseUrl}/nft-collection`,
  ACTIVITY: `${baseUrl}/activity`,
  CONTACT_US: `${baseUrl}/contact-us`,
};
