import HomePage from "./Home";
import ExplorePage from "./Explore";
import ActivityPage from "./Activity";
import BlogPage from "./Blog";
import BlogDetailsPage from "./BlogDetails";
import HelpCenterPage from "./HelpCenter";
import UserProfilePage from "./UserProfile";
import WalletConnectPage from "./WalletConnect";
import CreateItemPage from "./CreateItem";
import EditProfilePage from "./EditProfile";
import RankingPage from "./Ranking";
import NoResultPage from "./NoResult";
import FAQPage from "./FAQ";
import ContactPage from "./Contact";
import NFTDetails from "./NFTDetails";
import SearchPage from "./SearchPage";
import CollectionPage from "./Collection";

const routes = [
  { path: "/", component: <HomePage /> },
  { path: "/explore", component: <ExplorePage /> },
  { path: "/item-details/:id", component: <NFTDetails /> },
  { path: "/search", component: <SearchPage /> },
  { path: "/collection/:id", component: <CollectionPage /> },
  { path: "/activity", component: <ActivityPage /> },
  { path: "/blog", component: <BlogPage /> },
  { path: "/blog-details", component: <BlogDetailsPage /> },
  { path: "/help-center", component: <HelpCenterPage /> },
  { path: "/profile/:address", component: <UserProfilePage /> },
  { path: "/wallet-connect", component: <WalletConnectPage /> },
  { path: "/create-item", component: <CreateItemPage /> },
  { path: "/edit-profile", component: <EditProfilePage /> },
  { path: "/ranking", component: <RankingPage /> },
  { path: "/no-result", component: <NoResultPage /> },
  { path: "/faq", component: <FAQPage /> },
  { path: "/contact", component: <ContactPage /> },
];

export default routes;
