import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Explore from "../components/layouts/explore/Explore";
import { CollectionService } from "../services/collections.service";
import { NFTService } from "../services/nft.service";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const ExplorePage = () => {
  const collectionService = useMemo(() => new CollectionService(), []);
  const nftService = useMemo(() => new NFTService(), []);

  const [collections, setCollections] = useState();
  const [nftsBuyNow, setNftsBuyNow] = useState();
  // const [nftsOnAuction, setNftsOnAuction] = useState();
  const [visible, setVisible] = useState(3);

  // const [triggerTimeRefetch, setTriggerTimeRefetch] = useState(false);
  const [triggerFixedRefetch, setTriggerFixedRefetch] = useState(false);

  useEffect(() => {
    const getCollections = async () => {
      const data = (await collectionService.getCollections()).collections;
      setCollections(data);
    };

    getCollections();
  }, [collectionService]);

  useEffect(() => {
    const getNFTs = async () => {
      const data = (await nftService.getPaginatedNFTs('false', visible)).nfts;
      setNftsBuyNow(data.filter((nft) => nft.method === 'Fixed Price'));
      // setNftsOnAuction(data.filter((nft) => nft.method === 'Time Auctions' || nft.method === 'Open For Bids'));
    };

    getNFTs();
  }, [nftService, /*triggerTimeRefetch*/, triggerFixedRefetch, visible]);

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Explore</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Explore</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {collections && nftsBuyNow && nftsOnAuction ? ( */}
      {collections && nftsBuyNow ? (
        <Explore
          collections={collections}
          nftsBuyNow={nftsBuyNow}
          // nftsOnAuction={nftsOnAuction}
          visible={visible}
          setVisible={setVisible}
          // setTriggerTimeRefetch={setTriggerTimeRefetch}
          setTriggerFixedRefetch={setTriggerFixedRefetch}
        />
      ) : (
        <StyledContainer>
          <CircularProgress color="inherit" />
        </StyledContainer>
      )}
      < Footer />
    </div>
  );
};

export default ExplorePage;

const StyledContainer = styled.div`
  display: flex;
  background-color: #14141F;
  height: 200px;
  align-items: center;
  justify-content: center;
`;