import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/header/Header";
import SearchTabs from "../components/SearchTabs/SearchTabs";
import { getFilteredUsersBySearchPattern } from "../services/user.service.ts";
import SnackAction from "../components/snackAction/SnackAction";
import { Snackbar } from "@material-ui/core";
import { CollectionService } from "../services/collections.service";
import { NFTService } from "../services/nft.service";
import Footer from "../components/footer/Footer";

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [collections, setCollections] = useState([]);

  const [isLoadingNfts, setIsLoadingNfts] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);

  const [notify, setNotify] = useState();
  const [displayNotification, setDisplayNotification] = useState(false);

  const [nfts, setNfts] = useState([]);
  const [users, setUsers] = useState([]);

  const collectionService = useMemo(() => new CollectionService(), []);
  const nftService = useMemo(() => new NFTService(), []);

  const fetchUsers = useCallback(async() => {
    setIsLoadingUsers(true);
    await getFilteredUsersBySearchPattern(searchValue).then((response) => {
      setUsers(response.users);
      setNotify("Successfully fetched users!");
    }).catch((error) => { 
      console.log("error", error);
      setNotify("Something went wrong when searching for users");
    }).finally(() => {
      setDisplayNotification(true);
      setIsLoadingUsers(false);
     });
  },[searchValue]);

  const fetchCollections = useCallback(async() => {
    try {
      setIsLoadingCollections(true);
      const responseData = await collectionService.getFilteredCollections(searchValue);
      const collections = responseData.collections;
      setCollections(collections);
      setNotify("Successfully fetched collections!");

    } catch (error) {
      console.log("error", error);
      setNotify("Something went wrong when searching for collections");
    
    } finally {
      setDisplayNotification(true);
      setIsLoadingCollections(false);
    }
  },[collectionService, searchValue]);

  const fetchNfts = useCallback(async() => {
    try {
      setIsLoadingNfts(true);
      const responseData = await nftService.getFilteredNfts(searchValue);
      const nfts = responseData.nfts;
      setNfts(nfts);
      setNotify("Successfully fetched nfts!");

    } catch (error) {
      console.log("error", error);
      setNotify("Something went wrong when searching for nfts");
    
    } finally {
      setDisplayNotification(true);
      setIsLoadingNfts(false);
    }
  },[nftService, searchValue]);

  useEffect(() => {
    if (searchParams.get("q")) 
      setSearchValue(searchParams.get("q"));
  }, [searchParams]);

  useEffect(() => {
    if (searchValue.trim() === "") return;
    fetchUsers();
    fetchCollections();
    fetchNfts();
  }, [searchValue, fetchUsers, fetchCollections, fetchNfts]);

  return (
    <StyledContainer>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-15">
                <h2 className="heading text-left">
                  <span>Search results for </span>
                  {searchValue}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="themesflat-container">
          <SearchTabs collections={collections} nfts={nfts} users={users} isLoadingUsers={isLoadingUsers} isLoadingCollections={isLoadingCollections} isLoadingNfts={isLoadingNfts}/>
      </div>
      <Footer />

      <Snackbar
        className="snackbar-text"
        open={displayNotification}
        autoHideDuration={6000}
        onClose={() => {
          setDisplayNotification(false);
        }}
        message={notify}
        action={<SnackAction handleClose={setDisplayNotification} />}
      />
    </StyledContainer>
  );
};

export default SearchPage;

const StyledContainer = styled.div`
  .heading {
    > span {
      opacity: 0.7;
    }
  }
  .snackbar-text > * {
    font-size: 1.5rem !important;
  }
`;
