import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/reducers/userReducer.ts";
import { Link } from "react-router-dom";
import {
  coinTypes,
  bscCoinTypes,
  maticCoinTypes,
  ethCoinTypes,
  networks,
} from "../../utils/utils.ts";
import axios from "axios";
import Web3 from "web3";

const CardModal = ({ show, onHide, selectedItem, action }) => {
  const { toBN } = require("web3-utils");
  const user = useSelector(selectUser);
  const [amount, setAmount] = useState("");
  const [serviceFee] = useState("0.89");
  const [isInValidInput, setIsInValidInput] = useState(false);
  const [isUsersItem, setIsUsersItem] = useState(false);
  const [isCurrentBidder, setIsCurrentBidder] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0]);
  const [selectedCoinType, setSelectedCoinType] = useState(ethCoinTypes[0]);

  const [cryptoPrice, setCryptoPrice] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [serviceFeeAmount, setServiceFeeAmount] = useState();

  useEffect(async () => {
    if (user.connectedNetwork === "maticmum") {
      await changeNetwork("Polygon Testnet");
    } else if (user.connectedNetwork === "bnbt") {
      await changeNetwork("BSC Testnet");
    } else if (user.connectedNetwork === "ropsten") {
      await changeNetwork("Ethereum Testnet");
    }
    console.log(user);
    console.log(selectedItem);
  }, [user.connectedNetwork]);
  useEffect(async () => {
    if (selectedItem.method === "Fixed Price") {
      const web3 = new Web3(window.ethereum);
      const network = await web3.eth.net.getId();
      if (network === 3) {
        await changeNetwork("Ethereum Testnet");
      } else if (network === 80001) {
        await changeNetwork("Polygon Testnet");
      } else if (network === 97) {
        await changeNetwork("Binance Testnet");
      }
      console.log(network);
    } else if (selectedItem.method === "Time Auctions") {
      await changeNetwork("Polygon Testnet");
    }
  }, []);
  // useEffect(() => {
  //   if (selectedItem.method !== "Fixed Price") bidInMatic();
  // }, [amount]);

  const btnAction = async (type) => {
    setIsInValidInput(false);
    if (user?.address === selectedItem?.owner) {
      setIsUsersItem(true);
      return;
    }

    if (user?.address === selectedItem?.currentBidder) {
      setIsCurrentBidder(true);
      return;
    }

    if (type === "Time Auctions") {
      if (Number(selectedItem.currentBid) >= Number(amount)) {
        setIsInValidInput(true);
        return;
      }

      const web3 = new Web3(window.ethereum);
      const network = await web3.eth.net.getId();
      if (network !== 80001) {
        alert("Please select Mumbai Testnet");
        //await switchToPolygonNetwork();
      }
      if (selectedCoinType.type !== "crypt-polygon-matic") {
        setIsInValidInput(true);
        return;
      }
      console.log("Bid Amount: ", amount);
      const auctionContractId = "0xa7be8772cfa4aac73bb41c734e029a97b7fb70e7";
      const auctionContractAbi = require("../../utils/abi/AuctionContract.json");
      const AUCTIONCONTRACT = new web3.eth.Contract(
        auctionContractAbi,
        auctionContractId
      );
      const amountInWei = web3.utils.toBN(parseFloat(amount) * 1e18);
      const hexAmount = "0x" + amountInWei.toString(16);
      const tokenId = selectedItem.tokenId;
      console.log(tokenId);
      const body = {};
      body.amount = amount;
      body.networkId = network;
      try {
        await AUCTIONCONTRACT.methods
          .bid(tokenId)
          .send({ from: user.address, value: hexAmount })
          .on("transactionHash", async (hash) => {
            body.transactionHash = hash;
            await action(body);
          });
      } catch (error) {
        console.log(error);
      }
      //await action(amount);
    } else if (type === "Fixed Price") {
      const currency = selectedCoinType.type;
      const seller = selectedItem.owner; //TODO: clarify owner or creator
      const costInUSD = selectedItem.price;
      const costInCrypto = cryptoPrice;
      const serviceFee = serviceFeeAmount;
      const totalCost = totalPrice;
      const txnHash = "";
      const network = selectedNetwork;
      const status = 1;
      const body = {
        currency,
        seller,
        costInUSD,
        costInCrypto,
        serviceFee,
        totalCost,
        txnHash,
        network,
        status,
      };
      switch (currency) {
        case "crypt-bsc-bnb":
          await BNBPayment(body);
          break;
        case "crypt-bsc-busd":
          await BUSDPayment(body);
          break;
        case "crypt-polygon-matic":
          await MATICPayment(body);
          // await action(body);
          break;
        case "crypt-polygon-usdt":
          await USDTPosPayment();
          // await action(body);
          break;
        case "crypt-eth-eth":
          await ETHPayment();
          // await action(body);
          break;
        case "crypt-eth-usdt":
          await USDTEthPayment();
          // await action(body);
          break;
        default:
          alert("Unknown currency type");
          break;
      }

      //await action(amount);
    } else if (type === "Fixed Price") {
      await action();
    }

    closeModal();
  };

  const closeModal = () => {
    setAmount("");
    setIsInValidInput(false);
    setIsUsersItem(false);
    setIsCurrentBidder(false);
    onHide();
  };

  const changeCoinType = async (coin) => {
    console.log("coin type: ", coin.type);
    switch (coin.type) {
      case "crypt-bsc-bnb":
        //console.log("Selected Coins: ", selectedCoinType);
        await payWithBNB();
        break;
      case "crypt-bsc-busd":
        await payWithBUSD();
        break;
      case "crypt-polygon-matic":
        await payWithMATIC();
        break;
      case "crypt-polygon-usdt":
        await payWithUSDTMATIC();
        break;
      case "crypt-eth-eth":
        await payWithETH();
        break;
      case "crypt-eth-usdt":
        await payWithUSDTETH();
        break;
    }
    setSelectedCoinType(coin);
    // TODO - Change item price based on coin
  };

  const changeNetwork = async (network) => {
    console.log(network);
    if (network === "Binance Smart Chain" || network === "BSC Testnet") {
      await switchToBscNetwork();
      setSelectedNetwork(network);
      changeCoinType(bscCoinTypes[0]);
    } else if (network === "Polygon Mainnet" || network === "Polygon Testnet") {
      await switchToPolygonNetwork();
      setSelectedNetwork(network);
      changeCoinType(maticCoinTypes[0]);
    } else if (
      network === "Ethereum Mainnet" ||
      network === "Ethereum Testnet"
    ) {
      await switchToRopsten();
      setSelectedNetwork(network);
      changeCoinType(ethCoinTypes[0]);
    }
    //setSelectedNetwork(network);
    // TODO - Change network
  };

  const switchToBscNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x61" }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x61",
                chainName: "BSC-Testnet",
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://testnet.bscscan.com"],
              },
            ],
          });
        } catch (addError) {
          console.log("Adding Network Error: " + addError);
        }
      }
      console.log(error);
    }
  };

  const switchToPolygonNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x13881" }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x13881",
                chainName: "Mumbai",
                rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com"],
              },
            ],
          });
        } catch (addError) {
          console.log("Adding Network Error: " + addError.message);
        }
      }
      console.log(error);
    }
  };

  const switchToRopsten = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x3" }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x3",
                chainName: "Ropsten Test Network",
                rpcUrls: [
                  "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
                ],
                nativeCurrency: {
                  name: "Ether",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://ropsten.etherscan.io"],
              },
            ],
          });
        } catch (addError) {
          console.log("Adding Network Error: " + addError);
        }
      }
      console.log(error);
    }
  };

  const payWithBNB = async () => {
    console.log(selectedItem);
    const price =
      selectedItem.method === "Time Auctions"
        ? selectedItem.currentBid
        : selectedItem.price;
    const response = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=BNB"
    );
    const bnbPerUsd = response.data.BNB;
    //console.log(selectedItem.price, selectedItem.currentBid);
    const grossAmount = Number(price * bnbPerUsd);
    const serviceCharge = (grossAmount * Number(serviceFee)) / 100;
    const totalAmount = Number(grossAmount + serviceCharge);
    console.log(grossAmount);
    setCryptoPrice(grossAmount.toFixed(4));
    setServiceFeeAmount(serviceCharge.toFixed(4));
    setTotalPrice(totalAmount);
  };

  const payWithBUSD = async () => {
    const price =
      selectedItem.method === "Time Auctions"
        ? selectedItem.currentBid
        : selectedItem.price;
    const serviceCharge = (price * Number(serviceFee)) / 100;
    const totalPrice = Number(Number(price) + serviceCharge);
    setCryptoPrice(price);
    setServiceFeeAmount(serviceCharge.toFixed(4));
    setTotalPrice(totalPrice);
  };
  const payWithMATIC = async () => {
    const price =
      selectedItem.method === "Time Auctions" ? amount : selectedItem.price;
    const response = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=MATIC"
    );
    const maticPerUsd = response.data.MATIC;
    const grossAmount = Number(price) * maticPerUsd;
    const serviceCharge = (grossAmount * Number(serviceFee)) / 100;
    const totalAmount = grossAmount + serviceCharge;
    setCryptoPrice(grossAmount.toFixed(4));
    setServiceFeeAmount(serviceCharge.toFixed(4));
    setTotalPrice(totalAmount.toFixed(4));
  };

  const payWithUSDTMATIC = async () => {
    const price =
      selectedItem.method === "Time Auctions"
        ? selectedItem.currentBid
        : selectedItem.price;
    const serviceCharge = (price * Number(serviceFee)) / 100;
    const totalPrice = Number(Number(price) + serviceCharge);
    setCryptoPrice(price);
    setServiceFeeAmount(serviceCharge);
    setTotalPrice(totalPrice);
  };

  const payWithETH = async () => {
    const price =
      selectedItem.method === "Time Auctions"
        ? selectedItem.currentBid
        : selectedItem.price;
    const response = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH"
    );
    const ethPerUsd = response.data.ETH;
    const grossAmount = Number(price) * ethPerUsd;
    const serviceCharge = (grossAmount * Number(serviceFee)) / 100;
    const totalAmount = Number(grossAmount + serviceCharge);
    console.log(grossAmount.toFixed(4));
    setCryptoPrice(grossAmount.toFixed(4));
    setServiceFeeAmount(serviceCharge.toFixed(4));
    setTotalPrice(totalAmount);
  };
  const payWithUSDTETH = async () => {
    const price =
      selectedItem.method === "Time Auctions"
        ? selectedItem.currentBid
        : selectedItem.price;
    const serviceCharge = (price * Number(serviceFee)) / 100;
    const totalPrice = Number(Number(price) + serviceCharge);
    setCryptoPrice(price);
    setServiceFeeAmount(serviceCharge);
    setTotalPrice(totalPrice);
  };

  const BNBPayment = async (data) => {
    const amountInWei = toBN(parseFloat(totalPrice) * 1e18);
    const hexAmount = "0x" + amountInWei.toString(16);
    console.log(amountInWei.toString(), "amount In Wei");
    console.log(hexAmount, "amount In Hex");
    try {
      if (!window.ethereum) alert("Please Install Metamask!");
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: user.address,
              to: "0xb7Cd3878c7DfEf409894e66a33aA75C06881E4a5",
              value: hexAmount,
            },
          ],
        })
        .then(async (transactionHash) => {
          const txnHash = transactionHash;
          data.txnHash = txnHash;
          console.log(txnHash);
          await action(data);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const BUSDPayment = async (data) => {
    const amountInWei = toBN(parseFloat(totalPrice) * 1e18);
    try {
      if (!window.ethereum) alert("Please Install Metamask!");
      const web3 = new Web3(window.ethereum);
      const BUSDTOKENABI = require("../../utils/abi/BUSDABI.json");
      const BUSDTOKENADDRESS = "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee";
      const BUSDCONTRACT = new web3.eth.Contract(
        BUSDTOKENABI,
        BUSDTOKENADDRESS
      );
      await BUSDCONTRACT.methods
        .transfer("0xb7Cd3878c7DfEf409894e66a33aA75C06881E4a5", amountInWei)
        .send({ from: user.address })
        .on("transactionHash", async function (hash) {
          data.txnHash = hash;
          await action(data);
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const MATICPayment = async (data) => {
    const amountInWei = toBN(parseFloat(totalPrice) * 1e18);
    const hexAmount = "0x" + amountInWei.toString(16);
    try {
      if (!window.ethereum) alert("Please Install Metamask!");
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: user.address,
              to: "0xb7Cd3878c7DfEf409894e66a33aA75C06881E4a5",
              value: hexAmount,
            },
          ],
        })
        .then(async (transactionHash) => {
          const txnHash = transactionHash;
          data.txnHash = txnHash;
          await action(data);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const USDTPosPayment = async (data) => {
    const amountInWei = toBN(parseFloat(totalPrice) * 10 ** 18);
    try {
      if (!window.ethereum) alert("Please Install Metamask!");
      const web3 = new Web3(window.ethereum);
      const USDTPosABI = require("../../utils/abi/USDTPosABI.json");
      const USDTPosAddress = "0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1"; // Dummy (pos) erc-20 address
      const USDTPOSCONTRACT = new web3.eth.Contract(USDTPosABI, USDTPosAddress);
      await USDTPOSCONTRACT.methods
        .transfer("0xb7Cd3878c7DfEf409894e66a33aA75C06881E4a5", amountInWei)
        .send({ from: user.address })
        .on("transactionHash", async function (hash) {
          data.txnHash = hash;
          await action(data);
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const ETHPayment = async (data) => {
    const amountInWei = toBN(parseFloat(totalPrice) * 1e18);
    const hexAmount = "0x" + amountInWei.toString(16);
    try {
      if (!window.ethereum) alert("Please Install Metamask!");
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: user.address,
              to: "0xb7Cd3878c7DfEf409894e66a33aA75C06881E4a5",
              value: hexAmount,
            },
          ],
        })
        .then(async (transactionHash) => {
          const txnHash = transactionHash;
          data.txnHash = txnHash;
          await action(data);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const USDTEthPayment = async (data) => {
    const amountInWei = toBN(parseFloat(totalPrice) * 10 ** 18);
    try {
      if (!window.ethereum) alert("Please Install Metamask!");
      const web3 = new Web3(window.ethereum);
      const USDTPosABI = require("../../utils/abi/USDTEthABI.json");
      const USDTPosAddress = "0xaD6D458402F60fD3Bd25163575031ACDce07538D"; // Dummy (pos) erc-20 address
      const USDTPOSCONTRACT = new web3.eth.Contract(USDTPosABI, USDTPosAddress);
      await USDTPOSCONTRACT.methods
        .transfer("0xb7Cd3878c7DfEf409894e66a33aA75C06881E4a5", amountInWei)
        .send({ from: user.address })
        .on("transactionHash", async function (hash) {
          data.txnHash = hash;
          await action(data);
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        {selectedItem.method === "Time Auctions" ? (
          <h3>Place a Bid</h3>
        ) : (
          <h3>Buy Item</h3>
        )}
        <div className="d-flex justify-content-between">
          <p>Total price: </p>
          <p className="text-right price color-popup">
            {selectedItem.price} {"MATIC"}
          </p>
        </div>
        {isInValidInput && <ErrorMsg>Invalid Input</ErrorMsg>}
        {isCurrentBidder && (
          <ErrorMsg>You are the current highest bidder</ErrorMsg>
        )}  
        {isUsersItem &&
          (selectedItem.method === "Time Auctions" ? (
            <ErrorMsg>You cannot bid on your NFT</ErrorMsg>
          ) : (
            <ErrorMsg>You already own this NFT</ErrorMsg>
          ))}
        <button
          onClick={() =>
            selectedItem.method === "Time Auctions"
              ? btnAction("Time Auctions")
              : btnAction("Fixed Price")
          }
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#popup_bid_success"
          data-dismiss="modal"
          aria-label="Close"
        >
          {" "}
          {selectedItem.method === "Time Auctions" ? "Place a bid" : "Buy Item"}
        </button>
      </div>
    </Modal>
  );
};

export default CardModal;

const ErrorMsg = styled.p`
  color: red;
  text-align: center;
`;

const StyledDropdown = styled.div`
  border: 1px solid rgba(138, 138, 160, 0.3);
  border-radius: 4px;
  margin-top: 1rem;
`;
