import { Apis } from "../apis/apis";
import { postRequest, getRequest } from "./../utils/requests";

export const createActivityService = async (requestBody: any, token: string) =>
  await postRequest(Apis.ACTIVITY, requestBody, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getUserActivitiesService = async (userId: string) =>
  await getRequest(`${Apis.ACTIVITY}/${userId}`, null)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getAllSelectedActivities = async () => {
  return await getRequest(Apis.ACTIVITY, null)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}