export enum ActivityType {
  UserFollowing = 1,
  UserUnFollowing = 2,
  Common = 3,
  UserVerification = 4,
  NftLike = 5,
  NftDislike = 6,
  NftView = 7,
  NftBidding = 8
}
