import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import img1 from "../../assets/images/box-item/image-box-6.jpg";
import { NFTService } from "../../services/nft.service";

function CreateCollection({
  ipfsClient,
  onCreate,
  logo,
  setLogo,
  name,
  setName,
  symbol,
  setSymbol,
  desc,
  setDesc,
  url,
  setUrl,
  error,
  ...props
}) {
  const nftService = useMemo(() => new NFTService(), []);
  const uploadFile = async (media) => {
    try {
      // const added = await ipfsClient.add(media);
      // const logoUrl = `https://ipfs.infura.io/ipfs/${added.path}`;
      const { ipfsHash } = await nftService.AddImageToIPFS(media);
      const logoUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsHash}`;
      setLogo(logoUrl);
    } catch (err) {
      console.log("Error uploading file: ", err);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>Create Collection</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-collection-logo-container">
          <div>
            <img
              className="create-collection-logo"
              alt="logo"
              src={logo || img1}
            />
          </div>
          <div>
            <label className="uploadCollectionLogo">
              <span className="filename">
                We recommend an image of at least 300x300. Gifs work too. Max
                5mb.
              </span>
              <input
                type="file"
                className="inputfile form-control"
                name="file"
                onChange={(e) => uploadFile(e.target.files[0])}
              />
            </label>
          </div>
        </div>
        <form action="#">
          <h5 className="title-create-collection" style={{ marginTop: 0 }}>
            Display name
          </h5>
          <input
            type="text"
            placeholder="Enter collection name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <h5 className="title-create-collection">Symbol</h5>
          <input
            type="text"
            placeholder="Enter token symbol"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
          />
          <h5 className="title-create-collection">Description</h5>
          <input
            type="text"
            placeholder="Spread some words about your token collection"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <h5 className="title-create-collection">Short url</h5>
          <input
            type="text"
            placeholder="Enter Short Url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </form>
      </Modal.Body>
      <Modal.Footer style={{ border: 0, flexDirection: "column" }}>
        {error && (
          <div
            style={{
              display: "flex",
              marginBottom: "1rem",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "red" }}>{error}</p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={onCreate}>Create collection</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateCollection;
