import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import Follower from "./Follower";

const UserFollowersModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 style={{ margin: "1rem" }}>{props.isUserFollowers ? "Followers" : "Followings"}</h3>
        </Modal.Title>
      </Modal.Header>

      <div className="modal-body space-y-20 pd-15 scroll">
        <UserFollowerStyled>
         {props.data.map((user) => (
            <Follower key={user.address} follower={user} />
         ))}
          {props.data.length === 0 && ( 
            <div className="no-followers">
              <p>No {props.isUserFollowers ? "Followers" : "Followings"}</p>
            </div>
          )}
        </UserFollowerStyled>
      </div>
    </Modal>
  );
};

export default UserFollowersModal;

const UserFollowerStyled = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 25px;
  height: calc(100vh - 200px);

  .no-followers {
    text-align: center;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #ccc;
    border-radius: 10px;
  }
`;
