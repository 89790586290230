import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styled from "styled-components";
import { ethers } from "ethers";
import Web3Token from "web3-token";
import { useNavigate } from "react-router-dom";

import img1 from "../assets/images/icon/connect-1.png";
import img4 from "../assets/images/icon/connect-4.png";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  selectUser,
  updateAccessToken,
  updateAccountBalance,
  updateConnectedNetwork,
  setUserDetails,
} from "../redux/reducers/userReducer.ts";
import { getUserService, createUserService } from "../services/user.service.ts";
import {
  POLYGON_PROVIDER,
  POLYGON_EXPLORER,
  RINKEBY_PROVIDER,
  RINKEBY_EXPLORER,
  ETHEREUM_MAINNET_PROVIDER,
  ETHEREUM_EXPLORER,
  NETWORK_ID,
} from "../static/variables";

// import img8 from "../assets/images/icon/connect-8.png";
// import img2 from "../assets/images/icon/connect-2.png";
// import img3 from "../assets/images/icon/connect-3.png";
// import img5 from "../assets/images/icon/connect-5.png";
// import img6 from "../assets/images/icon/connect-6.png";
// import img7 from "../assets/images/icon/connect-7.png";

const WalletConnectPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleSwitchToMumbai = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x13881" }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x13881",
                chainName: "Mumbai",
                rpcUrls: [POLYGON_PROVIDER],
                nativeCurrency: {
                  name: "Matic",
                  symbol: "Matic",
                  decimals: 18,
                },
                blockExplorerUrls: [POLYGON_EXPLORER],
              },
            ],
          });
        } catch (addError) {
          console.log("Adding Network Error: " + addError.message);
          // toast.error("Adding network error");
          // add toastContainer
        }
      }
      console.log(error);
    }
  };

  const handleSwitchToRinkeby = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x4" }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x4",
                chainName: "Rinkeby",
                rpcUrls: [RINKEBY_PROVIDER],
                nativeCurrency: {
                  name: "Ether",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: [RINKEBY_EXPLORER],
              },
            ],
          });
        } catch (addError) {
          console.log("Adding Network Error: " + addError.message);
          // toast.error("Adding network error");
          // add toastContainer
        }
      }
      console.log(error);
    }
  };

  const handleSwitchToEthereum = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x1",
                chainName: "Ethereum Mainnet",
                rpcUrls: [ETHEREUM_MAINNET_PROVIDER],
                nativeCurrency: {
                  name: "Ether",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: [ETHEREUM_EXPLORER],
              },
            ],
          });
        } catch (addError) {
          console.log("Adding Network Error: " + addError.message);
          // toast.error("Adding network error");
          // add toastContainer
        }
      }
      console.log(error);
    }
  };

  const handleMetamaskConnect = async () => {
    try {
      setIsLoading(true);
      if (
        window.ethereum.selectedAddress === undefined ||
        window.ethereum.selectedAddress === null
      ) {
        let ethAccounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        dispatch(login({ address: ethAccounts[0] }));
      } else {
        dispatch(login({ address: window.ethereum.selectedAddress }));
      }

      if (NETWORK_ID === 4) await handleSwitchToRinkeby();
      if (NETWORK_ID === 80001) await handleSwitchToMumbai();
      if (NETWORK_ID === 1) await handleSwitchToEthereum();

      const accessToken = await generateToken();
      dispatch(updateAccessToken(accessToken));

      const accountBalance = await getUserAccount();
      dispatch(updateAccountBalance(accountBalance));

      const connectedNetwork = await getNetwork();
      dispatch(updateConnectedNetwork(connectedNetwork));

      const userData = await getUserService(accessToken);

      if (!userData.user) {
        const newUser = await createUserService({}, accessToken).finally(() =>
          setIsLoading(false)
        );
        dispatch(setUserDetails(newUser.user));
      } else {
        setIsLoading(false);
        dispatch(setUserDetails(userData.user));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNetwork = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    return network.name;
  };

  const getUserAccount = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    const balance = await provider.getBalance(accounts[0]);
    const accountBalance = ethers.utils.formatEther(balance);
    return accountBalance;
  };

  const generateToken = async () => {
    if (!window.ethereum) {
      alert("Please install and activate the metamask extension!");
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    try {
      return await Web3Token.sign(async (msg) => {
        try {
          return await signer.signMessage(msg);
        } catch (err) {
          const { reason } = err;
          if (reason === "unknown account #0") {
            alert("Have you unlocked metamask and are connected to this page?");
            return;
          }
          console.log(err.toString());
        }
      }, "2d");
    } catch (err) {
      if (/returns a signature/.test(err.toString())) {
        return;
      }
      console.log(err.toString());
    }
  };

  useEffect(() => {
    setData([
      {
        img: img1,
        title: "Meta Mask",
        onClickConnect: handleMetamaskConnect,
        description: user.address,
        isUserLoggedIn: user.address ? true : false,
        accountBalance: user.accountBalance,
        connectedNetwork: user.connectedNetwork,
      },
      {
        img: img4,
        title: "Wallet Connect",
        onClickConnect: () => {
          alert("Please install and use the metamask extension!");
        },
        description: "",
        isUserLoggedIn: false,
        accountBalance: "",
        connectedNetwork: "",
      },
    ]);
  }, [user]);

  return (
    <StyledComponents>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Connect Wallet</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Pages</Link>
                  </li>
                  <li>Connect Wallet</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-connect-wallet tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 mg-bt-12">
                Connect Your Wallet
              </h2>
              <h5 className="sub-title ct style-1 pad-400">
                Connect your wallet to get started.
              </h5>
            </div>
            <div className="col-md-12">
              <div className="sc-box-icon-inner style-2">
                {data.map((item, index) => (
                  <div key={index} className="sc-box-icon ">
                    <div className="img">
                      <img src={item.img} alt="Axies" />
                    </div>
                    <h4 className="heading">{item.title}</h4>
                    <p className="content">{item.description}</p>
                    {item.isUserLoggedIn && (
                      <h4 className="balance">
                        Balance: {item.accountBalance}
                      </h4>
                    )}
                    {item.isUserLoggedIn && (
                      <h4 className="network">
                        Network: {item.connectedNetwork}
                      </h4>
                    )}
                    {isLoading === true && user.accessToken && (
                      <h4 className="loading">please wait...</h4>
                    )}
                    {isLoading === true && !user.accessToken && (
                      <h4 className="loading">approve on metamask...</h4>
                    )}
                    <p className="content">
                      {!item.isUserLoggedIn && (
                        <button
                          className="wallet-btn"
                          onClick={item.onClickConnect}
                        >
                          Connect
                        </button>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </StyledComponents>
  );
};

export default WalletConnectPage;

const StyledComponents = styled.div`
  .wallet-btn {
    margin: 10px;
    color: white !important;
  }
  .content {
    word-break: break-word;
  }
  .network,
  .balance {
    color: #00bcd4;
    text-transform: uppercase;
    padding-top: 0.5pc;
  }
  .center {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
`;
