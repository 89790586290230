import React from "react";
import { Link } from "react-router-dom";

const UserActivty = ({ activity }) => {
  return (
    <div className="sc-card-activity style1" key={activity.time}>
      <div className="content">
        <div className="media">
          <img src={activity.img} alt="Axies" />
        </div>
        <div className="infor">
          <h3>
            {" "}
            <span className="p">{activity.title}</span>
          </h3>
          <div className="status">
            {activity.status} <span className="p">{activity.description}</span>
          </div>
          <div className="time">{activity.time}</div>
        </div>
      </div>
      <div className={`button-active icon ${activity.icon}`}></div>
    </div>
  );
};

export default UserActivty;
