import axios from "axios";
import { Apis } from "../apis/apis.ts";

export class ContactUsService {
    BASE_URL = Apis.CONTACT_US;

    async sendMessage(body) {
        await axios.post(this.BASE_URL, body);
    }

    async getMessagees() {
        const result = await axios.get(this.BASE_URL);
        return result.data;
    }

    async getUserMessagees(address) {
        const result = await axios.get(`${this.BASE_URL}?address=${address}`);
        return result.data;
    }

    async respondMessage(id, body) {
        const result = await axios.put(`${this.BASE_URL}/${id}`, body);
        return result.data;
    }

    async getMessage(id) {
        const result = await axios.get(`${this.BASE_URL}/${id}`);
        return result.data;
    }
}