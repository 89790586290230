import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Countdown from "react-countdown";
import CardModal from "../CardModal";
import { selectUser } from "../../../redux/reducers/userReducer.ts";
import { NFTService } from "../../../services/nft.service";
import ReactPlayer from "react-player";

const Collection = (props) => {
  const data = props.data;

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  return (
    <section className="tf-section live-auctions">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="tf-title-heading style-1 ct">NFTs Of {data.name}</h2>
          </div>

          {data.nfts.slice(0, visible).map((item, index) => (
            <NFTItem key={index} item={item} />
          ))}
          {visible < data.nfts.length && (
            <div className="col-md-12 wrap-inner load-more text-center">
              <Link
                to="#"
                id="load-more"
                className="sc-button loadmore fl-button pri-3"
                onClick={showMoreItems}
              >
                <span>Load More</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

Collection.propTypes = {
  data: PropTypes.object.isRequired,
};

const NFTItem = (props) => {
  const user = useSelector(selectUser);
  const nftService = useMemo(() => new NFTService(), []);

  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const defaultUserProfileImage = "https://niftron.infura-ipfs.io/ipfs/QmcFee3QWZLin6tkHJvDC2o3pXQcSnoJHWDX3k3S5a43tQ";

  const buyItem = async () => {
    props.setTriggerRefetch(false);
    await nftService.buyNFT(selectedItem, selectedItem._id, user.address);
    props.setTriggerRefetch(true);
    setModalShow(false);
  };

  const bidOnItem = async (amount) => {
    props.setTriggerTimeRefetch(false);
    await nftService.bidOnNFT(selectedItem, selectedItem._id, amount, user.address);
    props.setTriggerTimeRefetch(true);
    setModalShow(false);
  };

  const FileContent = ({ nft }) =>
    !nft.isFileVideo ? (
      <img src={nft.file} alt="Axies" />
    ) : (
      <ReactPlayer height="200px" width="100%" controls playing loop url={nft.file} />
    );

  return (
    <div className="fl-item col-xl-3 col-lg-6 col-md-6">
      <div className="sc-card-product">
        <div className="card-media">
          <Link to={`/item-details/${props.item._id}`}>
            <FileContent nft={props.item} />
          </Link>
          {props.item.startingDate && (
            <div className="featured-countdown">
              <span className="slogan"></span>
              <Countdown date={new Date(props.item.startingDate).getTime()}>
                {(Date.now() > new Date(props.item.endingDate).getTime()) ? (
                  <span>Auction Item Expired</span>
                ) : (
                  <span>You are good to go!</span>
                )}
              </Countdown>
            </div>
          )}
        </div>
        <div className="card-title">
          <h5>
            <Link to={`/item-details/${props.item._id}`}>"{props.item.title}"</Link>
          </h5>
        </div>
        <div className="meta-info">
          <div className="author">
            <div className="avatar">
              <img src={props.item.creatorDetails?.profileImage || defaultUserProfileImage} alt="axies" />
            </div>
            <div className="info">
              <span>Creator</span>
              <h6>
                {" "}
                <Link to={`/profile/${props.item.creatorDetails?.address.toLowerCase()}`}>
                  {props.item?.creatorDetails?.displayName ||
                    `${props.item.creator?.toLowerCase().slice(
                      0,
                      15
                    )}...`}
                </Link>{" "}
              </h6>
            </div>
          </div>
          <div className="price">
            {props.item.startingDate ? (
              <>
                <span>Current Bid</span>
                <h5> {props.item.currentBid} ETH</h5>
              </>
            ) : (
              <>
                <span>Price</span>
                <h5> {props.item.price} ETH</h5>
              </>
            )}
          </div>
        </div>
        {user?.address === props.item?.creatorPublicKey && (
          <div className="card-bottom">
            <button
              onClick={() => {
                setSelectedItem(props.item);
                setModalShow(true);
              }}
              className="sc-button style bag fl-button pri-3 no-bg"
            >
              {props.item.startingDate ? (
                <span>Place bid</span>
              ) : (
                <span>Buy Now</span>
              )}
            </button>

            <Link to="/activity" className="view-history reload">
              View History
            </Link>
          </div>
        )}
      </div>

      {selectedItem && (
        <CardModal
          action={(amount) => props.item.startingDate ? bidOnItem(amount) : buyItem()}
          selectedItem={selectedItem}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </div>
  );
};

export default Collection;
