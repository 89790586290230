import { Avatar } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import truncate from "truncate";

const TopSellerItem = (props) => {
  const navigate = useNavigate();

  const navigateToUserProfile = () => {
    if (props.isUser) {
      navigate(`/profile/${props.item.address}`);
    }
  };

  return (
    <TopSellerItemStyled className={`box-item`} onClick={navigateToUserProfile}>
      <div className="sc-author-box style-3 pd-0">
        <div className="author-avatar">
          <Avatar className="avatar" src={props.item.profileImage ?? props.item.displayName} />
          {props.item.isVerified && (
            <div className="badge">
              <i className="ripple"></i>
            </div>
          )}
        </div>
        <div className="author-infor">
          <h5 className="fs-16">{props.item.displayName ?? truncate(props.item.address, 8)}</h5>
          {props.isNft && (
            <span className="price">Price: {props.item.price ?? "0"}</span>
          )}
          {props.isUser && (
            <span className="address">
              {props.item.address.slice(0, 12)}...
            </span>
          )}
          {props.isCollection && (
            <span className="nft-count">
              Number of nfts: {props.item.nftCount}
            </span>
          )}
        </div>
      </div>
    </TopSellerItemStyled>
  );
};

export default TopSellerItem;

const TopSellerItemStyled = styled.div`
  cursor: pointer;

  .nft-count,
  .address,
  .price {
    font-size: 1.5rem;
  }
`;
