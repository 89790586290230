import React from "react";
import styled from "styled-components";
import TopSellerItem from "./TopSellerItem";

const TopSeller = (props) => {
  const data = props.data;
  return (
    <section className="tf-section top-seller">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title mb-25">Our Artists</h2>
            </div>
          </div>
          {data.length === 0 && (
            <NotFoundStyledContainer>
              <h4>No artists found</h4>
            </NotFoundStyledContainer>
          )}
          <div className="col-md-12">
            <div className="tf-box">
              {data.slice(0, 10).map((item, index) => (
                <TopSellerItem key={index} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopSeller;

const NotFoundStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex: 1;
  border: 1px solid;
  border-radius: 10px;
  margin-bottom: 20px;

  h4 { 
    padding: 1rem;
  }

  p {
    color: grey;
    padding: 1rem;
    font-size: 1.6rem;
  }

  button {
    margin: 1rem;
    padding: 1.5rem 2rem;

    &:hover {
      color: #fff;
      background-color: #4032ff;
    }
  }
`;
