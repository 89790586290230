import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Scrollbar, A11y } from "swiper";
import ReactPlayer from "react-player";

const PopularCollection = (props) => {
  const data = props.data;
  return (
    <section className="tf-section popular-collection">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title pb-22 text-left">Popular Collections</h2>
              <Link to="/explore" className="exp style2">
                EXPLORE MORE
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="collection">
              <Swiper
                modules={[Navigation, Scrollbar, A11y]}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },

                  767: {
                    slidesPerView: 2,
                  },

                  991: {
                    slidesPerView: 3,
                  },
                }}
                scrollbar={{ draggable: true }}
              >
                {data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <PopularCollectionItem item={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
PopularCollection.propTypes = {
  data: PropTypes.array.isRequired,
};

const PopularCollectionItem = ({ item }) => {
  const defaultImage = require("../../assets/images/box-item/collection-item-top-1.jpg");

  const FileContent = ({ nft, top, bottom, main }) =>
    !nft.isFileVideo ? (
      <img src={nft.file} alt="Axies" />
    ) : (
      <ReactPlayer
        url={nft.file}
        height={main ? '272px' : bottom ? '162px' : '100px'}
        width={top ? '48%' : '100%'}
        controls
        playing 
        loop
      />
    );

  return (
    <div className="swiper-container show-shadow carousel4 button-arow-style">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="slider-item">
            <div className="sc-card-collection style-2 home2">
              <div className="card-bottom">
                <div className="author">
                  <div className="sc-author-box style-2">
                    <div className="author-avatar">
                      <img
                        src={item?.userDetails?.profileImage || defaultImage.default}
                        alt=""
                        className="avatar"
                      />
                      <div className="badge">
                        <i className="ripple"></i>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to={`/collection/${item._id}`}>{item.name}</Link>
                    </h4>
                    <div className="infor">
                      <span>Created by</span>
                      <span className="name">
                        <Link to={`/profile/${item.userDetails?.address.toLowerCase()}`}>
                          {item?.userDetails?.displayName ||
                            `${item.userDetails?.address?.toLowerCase().slice(
                              0,
                              15
                            )}...`}
                        </Link>{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <Link to="/wallet-connect" className="wishlist-button public heart">
                <span className="number-like"> 100</span>
              </Link> */}
              </div>
              <Link to={`/collection/${item._id}`}>
                <div className="media-images-collection">
                  <div className="box-left">
                    {item.nftDetails[0] ? (
                      <FileContent nft={item.nftDetails[0]} main />
                    ) : (
                      <img src={defaultImage.default} alt="Axies" />
                    )}
                  </div>
                  <div className="box-right">
                    <div className="top-img">
                      {item.nftDetails[1] ? (
                        <FileContent nft={item.nftDetails[1]} top />
                      ) : (
                        <img src={defaultImage.default} alt="Axies" />
                      )}
                      {item.nftDetails[2] ? (
                        <FileContent nft={item.nftDetails[2]} top />
                      ) : (
                        <img src={defaultImage.default} alt="Axies" />
                      )}
                    </div>
                    <div className="bottom-img">
                      {item.nftDetails[3] ? (
                        <FileContent nft={item.nftDetails[3]} bottom />
                      ) : (
                        <img src={defaultImage.default} alt="Axies" />
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularCollection;
