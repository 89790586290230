import Switch from "react-switch";
import styled from "styled-components";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import img1 from "../assets/images/avatar/avt-3.jpg";
import img2 from "../assets/images/avatar/avt-11.jpg";
import img3 from "../assets/images/avatar/avt-1.jpg";
import img4 from "../assets/images/avatar/avt-5.jpg";
import img5 from "../assets/images/avatar/avt-7.jpg";
import img6 from "../assets/images/avatar/avt-8.jpg";

import { Link, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineHeart, AiFillHeart, AiOutlineEye } from "react-icons/ai";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import CardModal from "../components/layouts/CardModal";
import EditNFTModal from "../components/layouts/EditNFTModal";
import LiveAuction from "../components/layouts/home/LiveAuction";
import {
  selectUser,
  selectUserDetails,
} from "../redux/reducers/userReducer.ts";

import { NftAction } from "../enum/Nft.ts";
import { ActivityType } from "../enum/Activity.ts";
import { NFTService } from "../services/nft.service";
import { createActivityService } from "../services/activity.service.ts";
import { useCallback } from "react";
import Web3 from "web3";
import ReactPlayer from "react-player";
import truncate from "truncate";
import {
  ETHEREUM_MAINNET_NFT_CONTRACT_ID,
  MUMBAI_NFT_CONTRACT_ID,
  RINKEBY_NFT_CONTRACT_ID,
} from "../static/variables";

const NFTDetails = () => {
  const [biddingHistoryData, setBiddingHistoryData] = useState([]);
  const [isLoadingBiddingHistory, setIsLoadingBiddingHistory] = useState(false);
  const [isLoadingNetworkCalls, setIsLoadingNetworkCalls] = useState(false);

  const nftService = useMemo(() => new NFTService(), []);
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  const { id } = useParams();
  const accessToken = user.accessToken;

  const [nft, setNft] = useState();
  // const [liveAuctions, setLiveAuctions] = useState();
  // const [triggerTimeRefetch, setTriggerTimeRefetch] = useState(false);
  const [triggerSingleRefetch, setTriggerSingleRefetch] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isItemExpired, setIsItemExpired] = useState(false);

  const [toEditMethod, setToEditMethod] = useState("Fixed Price");
  const [toEditPrice, setToEditPrice] = useState();
  const [toEditMinBid, setToEditMinBid] = useState();
  const [toEditStartingDate, setToEditStartingDate] = useState();
  const [toEditEndingDate, setToEditEndingDate] = useState();

  const [showEditNFTModal, setShowEditNFTModal] = useState(false);
  const [isToggleLike, setisToggleLike] = useState(false);
  const [isUserViewed, setIsUserViewed] = useState(false);
  const [isUserViewedToggle, setIsUserViewedToggle] = useState(false);
  const [isLikeHandleLoading, setIsLikeHandleLoading] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [putOnMP, setPutOnMP] = useState(true);
  const [network, setNetwork] = useState();
  const [metamaskData, setmetamaskData] = useState(undefined);
  const [price, setPrice] = useState(0);
  const [isDisplayPriceField, setIsDisplayPriceField] = useState(false);
  const [message, setMessage] = useState("");
  const [displayMessage, setDisplayMessage] = useState(false);
  const defaultUserProfileImage =
    "https://niftron.infura-ipfs.io/ipfs/QmcFee3QWZLin6tkHJvDC2o3pXQcSnoJHWDX3k3S5a43tQ";

  const handleToggleLike = async () => {
    const userId = userDetails._id;
    const nftId = id;
    setIsLikeHandleLoading(true);
    let type = isToggleLike ? NftAction.Dislike : NftAction.Like;

    await nftService
      .handleLikeNft(userId, nftId, type)
      .then(() => {
        setisToggleLike(!isToggleLike);
        if (isToggleLike) {
          setLikeCount(likeCount - 1);
        } else {
          setLikeCount(likeCount + 1);
        }
      })
      .finally(() => {
        setIsLikeHandleLoading(false);
      });
  };

  // useEffect(() => {
  //   const getInitialData = async () => {
  //     const timeAuctionData = (await nftService.getNFTsOfType("Time Auctions"))
  //       .nfts;
  //     setLiveAuctions(timeAuctionData);
  //   };

  //   getInitialData();
  // }, [nftService, triggerTimeRefetch]);

  const handleEndAuction = async () => { };

  const handleBiddingHistory = useCallback(async () => {
    if (id) {
      setIsLoadingBiddingHistory(true);
      const biddingHistory = (await nftService.getNftBiddingHistory(id))
        .nftBidHistory;
      console.log(biddingHistory);
      const dataFormatted = biddingHistory?.map((bidHistory) => {
        return {
          displayName: bidHistory.bidder.displayName,
          address: bidHistory.bidder.address,
          bidAmount: bidHistory.bisAmount,
          createdAt: bidHistory.createdAt,
        };
      });
      dataFormatted?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setBiddingHistoryData(dataFormatted);
      setIsLoadingBiddingHistory(false);
    }
  }, [id, nftService]);

  useEffect(() => {
    handleBiddingHistory();
  }, [handleBiddingHistory]);

  useEffect(() => {
    const getInitialData = async () => {
      const nftData = (await nftService.getSingleNFT(id)).nft;
      const usersLiked = nftData.usersLiked || [];
      const usersViewed = nftData.usersViewed || [];
      const userId = userDetails._id;
      const likeCount = nftData.likes || 0;
      const metadataHash = nftData.metadata;
      const metadata = await nftService.getIpfsMetadata(metadataHash);
      console.log("metadata", metadata);

      const isLiked = usersLiked.includes(userId);
      const isViewed = usersViewed.includes(userId);

      setmetamaskData(metadata);
      setLikeCount(likeCount);
      setisToggleLike(isLiked);
      setIsUserViewed(isViewed);
      setPutOnMP(nftData.putOnMarket);
      setNft(nftData);
      setIsItemExpired(Date.now() > new Date(nftData.endingDate).getTime());
    };

    getInitialData();
  }, [triggerSingleRefetch, nftService, id, userDetails]);

  useEffect(() => {
    if (!isUserViewed && accessToken && !isUserViewedToggle && id && nft) {
      const updateUserViewNft = async () => {
        const userId = userDetails._id;
        const nftId = id;

        const activityRequestBody = {
          fromUser: userId,
          nftId: id,
          type: ActivityType.NftView,
        };

        await nftService
          .handleAddViewNft(userId, nftId)
          .then(async () => {
            await createActivityService(activityRequestBody, accessToken)
              .then(() => {
                setIsUserViewedToggle(true);
                setIsUserViewed(true);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      };
      updateUserViewNft();
    }
  }, [
    isUserViewed,
    isUserViewedToggle,
    accessToken,
    nftService,
    id,
    userDetails,
    nft,
  ]);

  const buyItem = async (amount) => {
    setTriggerSingleRefetch(false);
    await nftService.buyNFT(
      selectedItem,
      selectedItem._id,
      user.address,
      amount.seller,
      amount.costInUSD,
      amount.costInCrypto,
      amount.serviceFee,
      amount.totalCost,
      amount.currency,
      amount.txnHash,
      amount.network,
      amount.status
    );
    setTriggerSingleRefetch(true);
    setModalShow(false);
  };

  const handleAddPriceNft = async () => {
    if (price === 0) {
      setDisplayMessage(true);
      setMessage("Price cannot be zero");
      return;
    } else {
      setDisplayMessage(false);
      setMessage("");
    }

    setIsLoadingNetworkCalls(true);

    console.log("working on adding the price");

    // ----- without blockchain part -------

    // await nftService.editNFT(nft._id, {
    //   ...nft,
    //   price: price,
    //   putOnMarket: true,
    // }).then(() => {
    //   setIsDisplayPriceField(false);
    //   setPutOnMP(true);
    //   setTriggerSingleRefetch(true);
    // }).catch((error) => { console.log(error) })
    // .finally(() => { setIsLoadingNetworkCalls(false) });

    // -------------------------------------

    // ----- with blockchain part -------
    const web3 = new Web3(window.ethereum);
    const mpStatus = !putOnMP;
    const price_ = price;
    const tokenId = nft.tokenId;
    const priceInWei = web3.utils.toBN(parseFloat(price) * 1e18);
    const netId = await web3.eth.net.getId();
    let nftContractId;
    if (netId === 1) nftContractId = ETHEREUM_MAINNET_NFT_CONTRACT_ID;
    if (netId === 4) nftContractId = RINKEBY_NFT_CONTRACT_ID;
    if (netId === 80001) nftContractId = MUMBAI_NFT_CONTRACT_ID;

    const nftContractABI = require("../utils/abi/ENFTABI.json");
    const ElevateNFT = new web3.eth.Contract(nftContractABI, nftContractId);

    try {
      await ElevateNFT.methods
        .lockNFTForSale(tokenId, priceInWei)
        .send({ from: user.address })
        .on("receipt", async (receipt) => {
          const hash = receipt.transactionHash;
          await nftService
            .editNFT(nft._id, {
              ...nft,
              price: price_,
              putOnMarket: mpStatus,
              escrowNFTTxnHash: hash,
            })
            .then(() => {
              console.log("........4");
              setIsDisplayPriceField(false);
              setPutOnMP(true);
              setTriggerSingleRefetch(true);
            });
          // }
        });
    } catch (error) { }
  };

  const bidOnItem = async (amount) => {
    setTriggerSingleRefetch(false);
    console.log("triggering bid on ite....");
    console.log(amount);
    await nftService
      .bidOnNFT(
        selectedItem,
        selectedItem._id,
        amount.amount,
        amount.transactionHash,
        amount.networkId,
        user.address
      )
      .then(async () => {
        console.log("trigger bid on ite success");
        const biddingActivity = {
          nftId: selectedItem._id,
          userAddress: user.address,
          bid: amount,
          type: ActivityType.NftBidding,
        };
        await createActivityService(biddingActivity, accessToken).then(() => {
          console.log("create activity success for bidding");
        });
        handleBiddingHistory();
      });
    setTriggerSingleRefetch(true);
    setModalShow(false);
  };

  const handleMintNFT = async () => {
    console.log(nft);
    console.log(user);

    const userAddress = user.address;
    const tokenURI = nft.metadata;
    const royalties = nft.royalties;

    const royalty = Number(royalties) * 10 ** 2;
    console.log(royalty);

    const web3 = new Web3(window.ethereum);

    const netId = await web3.eth.net.getId();
    let nftContractId;
    if (netId === 1) nftContractId = ETHEREUM_MAINNET_NFT_CONTRACT_ID;
    if (netId === 4) nftContractId = RINKEBY_NFT_CONTRACT_ID;
    if (netId === 80001) nftContractId = MUMBAI_NFT_CONTRACT_ID;

    const contractABI = require("../utils/abi/ENFTABI.json");
    const NFTCONTRACT = new web3.eth.Contract(contractABI, nftContractId);

    setTriggerSingleRefetch(false);
    await NFTCONTRACT.methods
      .mintWithRoyalty(userAddress, tokenURI, nft.royaltyPublicKey, royalty)
      .send({ from: user.address })
      .on("transactionHash", async (hash) => {
        console.log(hash);
        await nftService
          .editNFT(nft._id, {
            ...nft,
            mintTxnHash: hash,
            mintInitiated: true,
          })
          .then(() => {
            alert("Minting ran successfully....");
            // setTriggerSingleRefetch(true);
            window.location.reload();
          });
      });
  };

  const updateOnMPStatus = async () => {
    const mpStatus = !putOnMP;

    if (mpStatus) {
      // onEditNFTClick();
      setIsDisplayPriceField(true);
    } else {
      console.log("toggle is set to false");
      // setPutOnMP(false);
      // await nftService.editNFT(nft._id, {
      //   ...nft,
      //   putOnMarket: mpStatus,
      // });
    }
  };

  const onEditNFTClick = () => {
    setToEditPrice(nft.price || "");
    setToEditMinBid(nft.minBid || "");
    setToEditStartingDate(nft.startingDate || "");
    setToEditEndingDate(nft.endDate || "");
    setShowEditNFTModal(true);
  };

  const onPutOnSale = async () => {
    console.log("on putting on sale toEditMethod:", toEditMethod);
    switch (toEditMethod) {
      case "Time Auctions": {
        await nftService.editNFT(nft._id, {
          ...nft,
          minBid: toEditMinBid,
          currentBid: toEditMinBid,
          bidderAddress: user.address,
          startingDate: toEditStartingDate,
          endingDate: toEditEndingDate,
          method: toEditMethod,
          putOnMarket: true,
        });
        break;
      }
      case "Open For Bids": {
        await nftService.editNFT(nft._id, {
          ...nft,
          minBid: toEditMinBid,
          currentBid: toEditMinBid,
          bidderAddress: user.address,
          startingDate: new Date().toISOString().split("T")[0],
          endingDate: toEditEndingDate,
          method: "Time Auctions",
          putOnMarket: true,
        });
        break;
      }
      case "Fixed Price":
        const {
          startingDate,
          endingDate,
          currentBid,
          currentBidder,
          minBid,
          ...rest
        } = nft;
        console.log(rest);
        await nftService.editNFT(nft._id, {
          ...rest,
          price: toEditPrice,
          method: toEditMethod,
          putOnMarket: true,
        });
        break;
      default: {
        break;
      }
    }

    setPutOnMP(true);
  };

  const FileContent = () =>
    !nft.isFileVideo ? (
      <img src={nft.file || img1} alt="Axies" />
    ) : (
      <ReactPlayer width="100%" controls playing loop url={nft.file} />
    );

  return (
    <div className="item-details">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">NFT Details</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>NFT Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {!nft || !liveAuctions ? ( */}
      {!nft ? (
        <ProgressContainer>
          <CircularProgress color="primary" />
        </ProgressContainer>
      ) : (
        <>
          <div className="tf-section tf-item-details style-2">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-xl-6 col-md-12">
                  <div className="content-left">
                    <div className="media">
                      <FileContent />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="content-right">
                    <div className="sc-item-details">
                      <div className="meta-item">
                        <div className="left">
                          <h2>{nft.title}</h2>
                        </div>

                        <ViewLikeSection>
                          <span className="nft-views">
                            <AiOutlineEye fontSize={"large"} />
                            <span>{nft.views || 0}</span>
                          </span>
                          {!isLikeHandleLoading ? (
                            <span
                              className="nft-likes"
                              onClick={handleToggleLike}
                            >
                              {isToggleLike ? (
                                <AiFillHeart fontSize={"large"} />
                              ) : (
                                <AiOutlineHeart fontSize={"large"} />
                              )}
                              <span>{likeCount}</span>
                            </span>
                          ) : (
                            <span className="nft-likes">
                              <CircularProgress
                                className="circular-progress"
                                size={20}
                              />
                            </span>
                          )}
                        </ViewLikeSection>
                      </div>
                      <div className="client-infor sc-card-product">
                        <div className="meta-info">
                          <div className="author">
                            <div className="avatar">
                              <img
                                src={
                                  nft?.ownerDetails?.profileImage ||
                                  defaultUserProfileImage
                                }
                                alt="Axies"
                              />
                            </div>
                            <div className="info">
                              <span>Owned By</span>
                              <h6>
                                {" "}
                                <Link to={`/profile/${nft.owner}`}>
                                  {nft?.ownerDetails?.displayName ||
                                    truncate(nft.owner, 18)}
                                </Link>{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="avatar">
                              <img
                                src={
                                  nft?.creatorDetails?.profileImage ||
                                  defaultUserProfileImage
                                }
                                alt="Axies"
                              />
                            </div>
                            <div className="info">
                              <span>Create By</span>
                              <h6>
                                {" "}
                                <Link to={`/profile/${nft.creator}`}>
                                  {nft?.creatorDetails?.displayName ||
                                    truncate(nft.creator, 18)}
                                </Link>{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>{nft.description}</p>
                      <div className="meta-item-details">
                        <div
                          className="item-style-2 item-details"
                          style={{ width: "100%" }}
                        >
                          <ul className="list-details">
                            <li>
                              <span>Artist : </span>
                              <h6>
                                {nft?.creatorDetails?.displayName ||
                                  `${nft.creator
                                    ?.toLowerCase()
                                    .slice(0, 15)}...`}
                              </h6>{" "}
                            </li>
                            {/* <li>
                              <span>Size : </span>
                              <h6>{nft.size}</h6>{" "}
                            </li> */}
                            <li>
                              <span>Created At : </span>
                              <h6>
                                {new Date(nft.createdAt).toDateString()}
                              </h6>{" "}
                            </li>

                            <li>
                              <span>Collection : </span>
                              <h6>{nft.collectionDetails.name}</h6>
                            </li>

                            {nft.tokenId && (
                              <li>
                                <span>Token id : </span>
                                <h6>{nft.tokenId}</h6>
                              </li>
                            )}

                            {metamaskData && metamaskData.fee_recipient && (
                              <li>
                                <span>Royality Recipiant : </span>
                                <h6>
                                  {truncate(metamaskData.fee_recipient, 15)}
                                </h6>
                              </li>
                            )}

                            {metamaskData &&
                              metamaskData.seller_fee_basis_points && (
                                <li>
                                  <span>Royality Percentage : </span>
                                  <h6>
                                    {metamaskData.seller_fee_basis_points / 100}
                                    %
                                  </h6>
                                </li>
                              )}

                          </ul>
                        </div>
                        <div className="item-style-2">
                          {nft.method === "Time Auctions" && (
                            <div className="item meta-price">
                              <span className="heading">Current Bid</span>
                              <div className="price">
                                <div className="price-box">
                                  <h5>{nft.currentBid} Matic</h5>
                                  <span>
                                    ~ $
                                    {Math.ceil(
                                      Number(nft.currentBid) * 2016.44
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {nft.method === "Time Auctions" && (
                            <div className="item count-down">
                              <Countdown
                                date={new Date(nft.startingDate).getTime()}
                              >
                                {isItemExpired ? (
                                  <div>
                                    <span className="h3">
                                      Auction Item Expired
                                    </span>
                                    <button
                                      className="mt-4"
                                      onClick={handleEndAuction}
                                    >
                                      End Auction
                                    </button>
                                  </div>
                                ) : (
                                  <span>You are good to go!</span>
                                )}
                              </Countdown>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="meta-item-details">
                        <div
                          className="item-style-2 item-details"
                          style={{ width: "100%" }}
                        >
                          <ul className="list-details">


                            {metamaskData &&
                              metamaskData.attributes.length > 0 &&
                              metamaskData.attributes.map((attribute) => {
                                return (
                                  <li>
                                    {attribute.trait_type && (
                                      <span>
                                        {attribute.trait_type}:{" "}
                                        <strong>{attribute.value}</strong>{" "}
                                      </span>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="item-style-2">
                          {nft.method === "Time Auctions" && (
                            <div className="item meta-price">
                              <span className="heading">Current Bid</span>
                              <div className="price">
                                <div className="price-box">
                                  <h5>{nft.currentBid} Matic</h5>
                                  <span>
                                    ~ $
                                    {Math.ceil(
                                      Number(nft.currentBid) * 2016.44
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {nft.method === "Time Auctions" && (
                            <div className="item count-down">
                              <Countdown
                                date={new Date(nft.startingDate).getTime()}
                              >
                                {isItemExpired ? (
                                  <div>
                                    <span className="h3">
                                      Auction Item Expired
                                    </span>
                                    <button
                                      className="mt-4"
                                      onClick={handleEndAuction}
                                    >
                                      End Auction
                                    </button>
                                  </div>
                                ) : (
                                  <span>You are good to go!</span>
                                )}
                              </Countdown>
                            </div>
                          )}
                        </div>
                      </div>
                      {displayMessage && <p>{message}</p>}

                      {isDisplayPriceField && (
                        <PriceContainer>
                          <h4>Price</h4>
                          <input
                            type="text"
                            placeholder="Enter price for one item (ETH)"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                          {isLoadingNetworkCalls ? (
                            <CircularProgress
                              className="circular-progress"
                              size={20}
                            />
                          ) : (
                            <button
                              onClick={handleAddPriceNft}
                              disabled={isLoadingNetworkCalls}
                            >
                              Add
                            </button>
                          )}
                        </PriceContainer>
                      )}

                      {user?.address === nft?.creatorPublicKey &&
                        nft.isMinted === false &&
                        nft.mintInitiated === false &&

                        (
                          <button onClick={handleMintNFT}>Mint NFT</button>
                        )}

                      {user?.address === nft?.creatorPublicKey &&
                        (nft.method === "Fixed Price" &&
                          user?.address !== nft?.creatorPublicKey ? (
                          <button
                            onClick={() => {
                              setSelectedItem(nft);
                              setModalShow(true);
                            }}
                            className="sc-button style bag fl-button pri-3 no-bg"
                          >
                            <span>Buy Now</span>
                          </button>
                        ) : new Date(nft.startingDate).getTime() <
                          Date.now() ? (
                          !isItemExpired ? (
                            <button
                              onClick={() => {
                                setSelectedItem(nft);
                                setModalShow(true);
                              }}
                              className="sc-button style bag fl-button pri-3 no-bg"
                            >
                              <span>Place bid</span>
                            </button>
                          ) : (
                            <h5
                              style={{
                                textAlign: "center",
                                marginBottom: "3rem",
                              }}
                            >
                              This item is accepting no more bids
                            </h5>
                          )
                        ) : (
                          <h5
                            style={{
                              textAlign: "center",
                              marginBottom: "3rem",
                            }}
                          >
                            {/* This item will be available soon! */}
                          </h5>
                        ))}
                      {nft?.owner === user?.address && nft.isMinted === true && (
                        <div>
                          <label
                            className="label-create-item-show-on-mp"
                            htmlFor="put-on-market-place"
                          >
                            <h4>Put on marketplace</h4>
                            <Switch
                              checked={putOnMP}
                              onChange={updateOnMPStatus}
                              onColor="#9085ff"
                              onHandleColor="#5142FC"
                              handleDiameter={15}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={48}
                              className="react-switch"
                              id="put-on-market-place"
                            />
                          </label>
                        </div>
                      )}

                      {nft.method !== "Fixed Price" && (
                        <div className="flat-tabs themesflat-tabs">
                          <Tabs>
                            <TabList>
                              <Tab>Bid History</Tab>
                            </TabList>

                            {isLoadingBiddingHistory ? (
                              <TabPanel>
                                <div className="loading-container">
                                  <div className="loading">Loading...</div>
                                </div>
                              </TabPanel>
                            ) : (
                              <TabPanel>
                                <ul className="bid-history-list">
                                  {biddingHistoryData.map((item, index) => (
                                    <li key={index} item={item}>
                                      <div className="content">
                                        <div className="client">
                                          <div className="sc-author-box style-2">
                                            <div className="author-infor">
                                              <div className="name">
                                                <h6>{item?.displayName} </h6>{" "}
                                                <span> place a bid</span>
                                              </div>
                                              <span className="time">{`${new Date(
                                                item.createdAt
                                              ).toDateString()}, ${new Date(
                                                item.createdAt
                                              ).toLocaleTimeString()}`}</span>
                                              <div>
                                                <p>{item.address}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="price">
                                          <h5>{item.bidAmount} ETH</h5>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </TabPanel>
                            )}
                          </Tabs>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <LiveAuction
            setTriggerTimeRefetch={setTriggerTimeRefetch}
            data={liveAuctions.filter((la) => la._id.toString() !== id)}
          /> */}
        </>
      )}

      {selectedItem && (
        <CardModal
          action={(amount) =>
            nft.startingDate ? bidOnItem(amount) : buyItem(amount)
          }
          selectedItem={selectedItem}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}

      <EditNFTModal
        show={showEditNFTModal}
        onHide={() => setShowEditNFTModal(false)}
        method={toEditMethod}
        setMethod={setToEditMethod}
        price={toEditPrice}
        setPrice={setToEditPrice}
        minBid={toEditMinBid}
        setMinBid={setToEditMinBid}
        startingDate={toEditStartingDate}
        setStartingDate={setToEditStartingDate}
        endingDate={toEditEndingDate}
        setEndingDate={setToEditEndingDate}
        putOnSale={onPutOnSale}
        setTriggerSingleRefetch={setTriggerSingleRefetch}
      />
      <Footer />
    </div>
  );
};

export default NFTDetails;

const PriceContainer = styled.div`
  display: flex;
  padding: 1pc;
  margin: 2pc 0;
  align-items: center;
  justify-content: space-between;

  > input {
    margin: 0 1pc;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;

  > * {
    color: #fff !important;
  }
`;

const ViewLikeSection = styled.div`
  display: flex;
  align-items: center;

  .nft-views,
  .nft-likes {
    display: flex;
    align-items: center;

    > span {
      padding: 0.1pc 0.6pc !important;
    }
  }

  .circular-progress {
    color: white !important;
  }

  .nft-likes {
    cursor: pointer;
  }
`;
