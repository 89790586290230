import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { ContactUsService } from '../services/contact-us.service';
import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import SnackAction from '../components/snackAction/SnackAction';
import { authGuard } from "../utils/utils.ts";
import { selectUser } from "../redux/reducers/userReducer.ts";

const ContactPage = () => {
  const currentUser = useSelector(selectUser);
  const isUserLoggedIn = currentUser.accessToken !== null;

  const navigate = useNavigate();
  const contactUsService = useMemo(() => new ContactUsService(), []);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [isInValidInput, setIsInValidInput] = useState(false);
  const [notify, setNotify] = useState();
  const [displayNotification, setDisplayNotification] = useState(false);

  useEffect(() => {
    authGuard(isUserLoggedIn, navigate);
  }, [isUserLoggedIn, navigate]);

  const sendMessage = async (e) => {
    e.preventDefault();
    setIsInValidInput(false);
    setDisplayNotification(false);

    if (fullName.trim() === ''
      || email.trim() === ''
      || subject.trim() === ''
      || message.trim() === '') {
      setIsInValidInput(true);
      return;
    }

    await contactUsService.sendMessage({
      fullName,
      email,
      subject,
      message,
      address: currentUser.address.toLowerCase(),
    });

    setNotify('Your Message Has Been Received!');
    setDisplayNotification(true);
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Contact</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Contact</Link>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <StyledContainer className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h2 className="tf-title-heading style-2 mg-bt-12">
                Drop Us A Message
              </h2>
              <div className="form-inner">
                <form
                  id="contactform"
                  noValidate="novalidate"
                  className="form-submit"
                >
                  <input
                    id="name"
                    name="name"
                    tabIndex="1"
                    aria-required="true"
                    type="text"
                    placeholder="Your Full Name"
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    required
                  />
                  <input
                    id="email"
                    name="email"
                    tabIndex="2"
                    aria-required="true"
                    type="email"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <input
                    id="email"
                    name="email"
                    tabIndex="2"
                    aria-required="true"
                    type="text"
                    placeholder="Subject"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    required
                  />
                  <textarea
                    id="message"
                    name="message"
                    tabIndex="3"
                    aria-required="true"
                    placeholder="Message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    required
                  ></textarea>
                  {isInValidInput && <ErrorMsg>Please fill in all the fields</ErrorMsg>}
                  <button className="submit" onClick={sendMessage}>Send message</button>
                </form>
              </div>
            </div>
          </StyledContainer>
        </div>
      </section>

      <StyledSnackbar
        open={displayNotification}
        autoHideDuration={6000}
        onClose={() => {
          setDisplayNotification(false);
        }}
        message={notify}
        action={<SnackAction handleClose={setDisplayNotification} />}
      />
      <Footer />
    </div>
  );
};

export default ContactPage;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorMsg = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 2rem;
`;

const StyledSnackbar = styled(Snackbar)`
  > * {
    font-size: 1.5rem !important;
  }
`;
