import { User } from "../../types/User";
import { createSlice } from "@reduxjs/toolkit";
import { UserDetails } from "../../types/UserDetails";

const userState: User = {
  address: null,
  accessToken: null,
  accountBalance: null,
  connectedNetwork: null,
};

const userDetails: UserDetails = {
  _id: null,
  displayName: null,
  isVerified: null,
  isVerifyApproved: null,
  isVerifyReqCreated: null,
  customUrl: null,
  address: null,
  bio: null,
  twitterUsername: null,
  portfolioSite: null,
  email: null,
  profileImage: null,
  coverImage: null,
  createdAt: null,
  updatedAt: null,
  followers: [],
  followings: [],
  earning: null,
};

export const userReducer = createSlice({
  name: "user",
  initialState: {
    user: userState,
    userDetails: userDetails,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    editUserProfileDetails: (state, action) => {
      state.userDetails.bio = action.payload.bio;
      state.userDetails.email = action.payload.email;
      state.userDetails.customUrl = action.payload.customUrl;
      state.userDetails.displayName = action.payload.displayName;
      state.userDetails.profileImage = action.payload.profileImage;
      state.userDetails.portfolioSite = action.payload.portfolioSite;
      state.userDetails.twitterUsername = action.payload.twitterUsername;
      state.userDetails.isVerifyReqCreated = action.payload.isVerifyReqCreated;
    },
    updateAccessToken: (state, action) => {
      state.user.accessToken = action.payload;
    },
    updateAccountBalance: (state, action) => {
      state.user.accountBalance = action.payload;
    },
    updateConnectedNetwork: (state, action) => {
      state.user.connectedNetwork = action.payload;
    },
    addFollowing: (state, action) => {
      state.userDetails.followings!.push(action.payload);
    },
    removeFollowing: (state, action) => {
      const userId = action.payload["userId"];
      const updatedFollowings = state.userDetails.followings!.filter(
        (user) => user.userId !== userId
      );
      state.userDetails.followings = updatedFollowings;
    },
    logout: (state) => {
      state.user = userState;
    },
  },
});

export const {
  login,
  logout,
  updateAccessToken,
  updateAccountBalance,
  updateConnectedNetwork,
  setUserDetails,
  addFollowing,
  removeFollowing,
  editUserProfileDetails,
} = userReducer.actions;
export const selectUser = (state: any) => state.user.user;
export const selectUserDetails = (state: any) => state.user.userDetails;
export default userReducer.reducer;
