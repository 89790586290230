import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import img1 from "../../../assets/images/box-item/img_cart_item.jpg";
import img2 from "../../../assets/images/box-item/img_cart_item2.jpg";
import img3 from "../../../assets/images/box-item/img_cart_item3.png";
import img4 from "../../../assets/images/box-item/img_cart_item4.jpg";
import img5 from "../../../assets/images/box-item/img_cart_item5.jpg";
import img6 from "../../../assets/images/box-item/img_cart_item6.jpg";
import img7 from "../../../assets/images/box-item/img_cart_item7.jpg";

const Banner = ({ nfts }) => {
  const FileContent = ({ nft, tall }) =>
  !nft.isFileVideo ? (
    <img style={{ height: tall ? '430px' : '200px' }} src={nft.file} alt="Axies" />
  ) : (
    <ReactPlayer height={tall ? '430px' : '200px'} width="100%" controls playing loop url={nft.file} />
  );

  return (
    <section className="flat-cart-item">
      <div className="overlay"></div>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title mb-25" style={{color:"white"}}>Our Gallery</h2>
            </div>
            <div className="wrap-cart flex">
              <div className="cart_item">
                <div className="inner-cart">
                  <div className="overlay"></div>
                  {nfts[0]?.file ? (
                    <Link to={`/item-details/${nfts[0]._id}`}>
                      <div className="overlay"></div>
                      <FileContent nft={nfts[0]} />
                    </Link>
                  ) : (
                    <>
                      <div className="overlay"></div>
                      <img src={img1} alt="Axies" />
                    </>
                  )}
                  {nfts[0] && (
                    <div className="content">
                      <div className="fs-16">
                        <Link to={`/item-details/${nfts[0]?._id}`}>
                          {nfts[0]?.title}
                        </Link>
                      </div>
                      <Link to={`/collection/${nfts[0]?.collectionDetails._id}`}>
                        <p>{nfts[0]?.collectionDetails.name}</p>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="inner-cart">
                  {nfts[1]?.file ? (
                    <Link to={`/item-details/${nfts[1]?._id}`}>
                      <div className="overlay"></div>
                      <FileContent nft={nfts[1]} />
                    </Link>
                  ) : (
                    <>
                      <div className="overlay"></div>
                      <img src={img2} alt="Axies" />
                    </>
                  )}
                  {nfts[1] && (
                    <div className="content">
                      <div className="fs-16">
                        <Link to={`/item-details/${nfts[1]?._id}`}>
                          {nfts[1]?.title}
                        </Link>
                      </div>
                      <Link to={`/collection/${nfts[1]?.collectionDetails._id}`}>
                        <p>{nfts[1]?.collectionDetails.name}</p>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="cart_item style2">
                <div className="inner-cart">
                  {nfts[2]?.file ? (
                    <Link to={`/item-details/${nfts[2]?._id}`}>
                      <div className="overlay"></div>
                      <FileContent nft={nfts[2]} tall />
                    </Link>
                  ) : (
                    <>
                      <div className="overlay"></div>
                      <img src={img3} alt="Axies" style={{ height: '430px' }} />
                    </>
                  )}
                  {nfts[2] && (
                    <div className="content">
                      <div className="fs-16">
                        <Link to={`/item-details/${nfts[2]?._id}`}>
                          {nfts[2]?.title}
                        </Link>
                      </div>
                      <Link to={`/collection/${nfts[2]?.collectionDetails._id}`}>
                        <p>{nfts[2]?.collectionDetails.name}</p>
                      </Link>
                    </div>
                  )}
                  <div className="progress">
                    <div className="progress-bar"></div>
                  </div>
                </div>
              </div>
              <div className="cart_item">
                <div className="inner-cart">
                  {nfts[3]?.file ? (
                    <Link to={`/item-details/${nfts[3]?._id}`}>
                      <div className="overlay"></div>
                      <FileContent nft={nfts[3]} />
                    </Link>
                  ) : (
                    <>
                      <div className="overlay"></div>
                      <img src={img4} alt="Axies" />
                    </>
                  )}
                  {nfts[3] && (
                    <div className="content">
                      <div className="fs-16">
                        <Link to={`/item-details/${nfts[3]?._id}`}>
                          {nfts[3]?.title}
                        </Link>
                      </div>
                      <Link to={`/collection/${nfts[3]?.collectionDetails._id}`}>
                        <p>{nfts[3]?.collectionDetails.name}</p>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="inner-cart">
                  {nfts[4]?.file ? (
                    <Link to={`/item-details/${nfts[4]?._id}`}>
                      <div className="overlay"></div>
                      <FileContent nft={nfts[4]} />
                    </Link>
                  ) : (
                    <>
                      <div className="overlay"></div>
                      <img src={img5} alt="Axies" />
                    </>
                  )}
                  {nfts[4] && (
                    <div className="content">
                      <div className="fs-16">
                        <Link to={`/item-details/${nfts[4]?._id}`}>
                          {nfts[4]?.title}
                        </Link>
                      </div>
                      <Link to={`/collection/${nfts[4]?.collectionDetails._id}`}>
                        <p>{nfts[4]?.collectionDetails.name}</p>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="cart_item">
                <div className="inner-cart">
                  {nfts[5]?.file ? (
                    <Link to={`/item-details/${nfts[5]?._id}`}>
                      <div className="overlay"></div>
                      <FileContent nft={nfts[5]} />
                    </Link>
                  ) : (
                    <>
                      <div className="overlay"></div>
                      <img src={img6} alt="Axies" />
                    </>
                  )}
                  {nfts[5] && (
                    <div className="content">
                      <div className="fs-16">
                        <Link to={`/item-details/${nfts[5]?._id}`}>
                          {nfts[5]?.title}
                        </Link>
                      </div>
                      <Link to={`/collection/${nfts[5]?.collectionDetails._id}`}>
                        <p>{nfts[5]?.collectionDetails.name}</p>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="inner-cart">
                  {nfts[6]?.file ? (
                    <Link to={`/item-details/${nfts[6]?._id}`}>
                      <div className="overlay"></div>
                      <FileContent nft={nfts[6]} />
                    </Link>
                  ) : (
                    <>
                      <div className="overlay"></div>
                      <img src={img7} alt="Axies" />
                    </>
                  )}``
                  {nfts[6] && (
                    <div className="content">
                      <div className="fs-16">
                        <Link to={`/item-details/${nfts[6]?._id}`}>
                          {nfts[6]?.title}
                        </Link>
                      </div>
                      <Link to={`/collection/${nfts[6]?.collectionDetails._id}`}>
                        <p>{nfts[6]?.collectionDetails.name}</p>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
