import { Avatar } from "@material-ui/core";
import truncate from "truncate";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Follower = (props) => {
  const navigate = useNavigate();

  const handleNavigateToUserProfile = () => {
    navigate(`/profile/${props.follower.address}`);
    window.location.reload();
  };

  return (
    <FollowerStyled onClick={handleNavigateToUserProfile}>
      <Avatar
        src={props.follower.profilePhoto}
        className="avatar"
      />
      <div className="follower-content">
        <p>{props.follower.name ?? "Unknown"}</p>
        <small>{truncate(props.follower.address, 30)}</small>
      </div>
    </FollowerStyled>
  );
};

export default Follower;

const FollowerStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem 1rem;
  margin: 1rem;

  .avatar {
    margin-left: 1rem;
  }

  .follower-content {
    margin-left: 1rem;
    > p {
      font-size: 1.8rem;
      font-weight: bold;
      color: #ccc;
      &:hover {
        color: #fff;
      }
    }

    > small {
      font-size: 1.4rem;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
