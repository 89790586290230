import axios from "axios";
import { Apis } from "../apis/apis.ts";

export class CollectionService {
    BASE_URL = Apis.COLLECTIONS;

    async getCollections() {
        const result = await axios.get(this.BASE_URL);
        return result.data;
    }

    async getCollection(id) {
        const result = await axios.get(`${this.BASE_URL}/${id}`);
        return result.data;
    }

    async getPopularCollections() {
        const result = await axios.get(`${this.BASE_URL}/get-popular`);
        return result.data;
    }

    async getUserCollections(creator) {
        const result = await axios.get(`${this.BASE_URL}`, {
            params: {
                creator,
            },
        });
        return result.data;
    }

    async createDefaultCollection(creator) {
        await axios.post(
            `${this.BASE_URL}/create-default`, {}, {
                params: {
                    creator,
                },
            }
        );
    }

    async createCollection(body) {
        await axios.post(this.BASE_URL, body);
    }

    async getFilteredCollections(searchPattern) {
        const result = await axios.get(
            `${this.BASE_URL}/filter/name/${searchPattern}`
        );
        return result.data;
    }
}