import { Dialog } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";

const UserMessage = ({ message }) => {
  const [openMsg, setOpenMsg] = useState(false);

  return (
    <StyledContainer>
      <section onClick={() => setOpenMsg(true)} style={{ width: '100%' }}>
        <h3>{message.email}</h3>
        <p>
          {message.subject}
        </p>
        <p>
          {message.responded ? (
            <strong style={{ color: 'green' }}>
              Responded on {new Date(message.respondedAt).toDateString()}
            </strong>
          ) : (
            <span style={{ color: 'gray' }}>No Response Yet</span>
          )}
        </p>
      </section>

      <StyledDialog open={openMsg} onClose={() => setOpenMsg(false)}>
        <div>
          <p className="message-tag">You, {message.email}, sent the following message</p>
        </div>

        <ResponseContainer>
          <div>
            <p className="message-tag"><em>Subject</em></p>
            <p className="message-value">{message.subject}</p>
          </div>
          <div>
            <p className="message-tag"><em>Message</em></p>
            <p className="message-value">{message.message}</p>
          </div>
          <div>
            <p className="message-tag"><em>Response Received</em></p>
            <p className="message-value">
              {message.response || "No Response Yet"}
            </p>
          </div>
        </ResponseContainer>
      </StyledDialog>
    </StyledContainer>
  );
};

export default UserMessage;

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1pc;
  margin: 1pc;
  cursor: pointer;

  img {
    height: 50px;
    width: 45px;
    border-radius: 50%;
    margin: 1rem;
    margin-right: 1pc;
  }

  > section {
    padding-top: 1rem;

    > h3 {
      font-size: 1.8rem;
    }

    > p {
      padding-top: .8rem;
      font-size: 1.6rem;
      color: lightgrey;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  > div > div.MuiPaper-root {
    height: 100% !important;
    padding: 20px;
  }

  .message-tag {
    color: black;
    font-weight: bold;
  }

  .message-value {
    color: black;
  }
`;

const ResponseContainer = styled.div`
  > div {
    margin: 20px 0;
  }
`;
