import axios from "axios";
import { Apis } from "../apis/apis.ts";

export class NFTService {
    BASE_URL = Apis.NFT;

    async createNFT(body) {
        await axios.post(this.BASE_URL, body);
    }

    async getNFTs(includeNotOnSale = "false") {
        const result = await axios.get(this.BASE_URL, {
            params: {
                includeNotOnSale,
            },
        });
        return result.data;
    }

    async getNftBiddingHistory(id) {
        const result = await axios.get(`${this.BASE_URL}/bid-history/${id}`);
        return result.data;
    }

    async getPaginatedNFTs(includeNotOnSale = "false", displayCount = 3) {
        const result = await axios.get(this.BASE_URL, {
            params: {
                includeNotOnSale,
                displayCount,
            },
        });
        return result.data;
    }

    async getNFTsOfType(type) {
        const result = await axios.get(`${this.BASE_URL}`, {
            params: {
                type,
            },
        });
        return result.data;
    }

    async bidOnNFT(nft, id, bid, transactionHash, networkId, bidder) {
        const result = await axios.post(`${this.BASE_URL}/bid/${id}`, {
            nft,
            bid,
            transactionHash,
            networkId,
            bidderAddress: bidder,
        });
        return result.data;
    }

    async editNFT(id, body) {
        const result = await axios.put(`${this.BASE_URL}/${id}`, body);
        return result.data;
    }

    async buyNFT(
        nft,
        id,
        buyer,
        seller,
        costInUSD,
        costInCrypto,
        serviceFee,
        totalCost,
        currency,
        txnHash,
        network,
        status
    ) {
        const result = await axios.post(`${this.BASE_URL}/buy/${id}`, {
            nft,
            ownerAddress: buyer,
            seller: seller,
            costInUSD,
            costInCrypto,
            serviceFee,
            totalCost,
            currency,
            txnHash,
            network,
            status,
        });
        return result.data;
    }

    async handleAddViewNft(userId, nftId) {
        const result = await axios.patch(`${this.BASE_URL}/view/add`, {
            userId,
            nftId,
        });
        return result.data;
    }

    async handleLikeNft(userId, nftId, type) {
        const result = await axios.patch(`${this.BASE_URL}/handle/like`, {
            userId,
            nftId,
            type,
        });
        return result.data;
    }

    async burnNFT(id) {
        const result = await axios.delete(`${this.BASE_URL}/${id}`);
        return result.data;
    }

    async getUserNFTs(owner) {
        const result = await axios.get(`${this.BASE_URL}`, {
            params: {
                owner,
            },
        });
        return result.data;
    }

    async getSingleNFT(id) {
        const result = await axios.get(`${this.BASE_URL}/${id}`);
        return result.data;
    }

    async getFilteredNfts(searchPattern) {
        const result = await axios.get(
            `${this.BASE_URL}/filter/name/${searchPattern}`
        );
        return result.data;
    }

    async AddToIPFS(data) {
        try {
            const formData = new FormData();
            formData.append("base64", data);

            const res = await axios.post(
                "https://ipfs.infura.io:5001/api/v0/add",
                formData, {
                    headers: {
                        Authorization: "Basic MkRDUm14VDFwV3FWYkR3cHRzcWdKcVQ5V3d0OmQyYWI4NWMyYmE0ZmRmZjlhODRkOWVlMTc3MDM3OGE2",
                        // "User-Agent": "",

                        // 'Content-Length': file.length,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res === null) {
                throw Error("Failed to add data to IPFS");
            }
            return { ipfsHash: res.data.Hash, data: data };
        } catch (er) {
            console.log(er);
            throw new Error("Failed to add data to IPFS");
        }
    }

    async AddImageToIPFS(file) {
        try {
            const formData = new FormData();
            formData.append("image", file);

            const res = await axios.post(
                "https://ipfs.infura.io:5001/api/v0/add",
                formData, {
                    headers: {
                        Authorization: "Basic MkRDUm14VDFwV3FWYkR3cHRzcWdKcVQ5V3d0OmQyYWI4NWMyYmE0ZmRmZjlhODRkOWVlMTc3MDM3OGE2",
                        // "User-Agent": "",
                        // 'Content-Length': file.length,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res === null) {
                throw Error("Failed to add data to IPFS");
            }
            return { ipfsHash: res.data.Hash, data: file };
        } catch (er) {
            console.log(er);
            throw new Error("Failed to add data to IPFS");
        }
    }

    async AddMetaToIPFS(data) {
        try {
            const formData = new FormData();
            formData.append("base64", data);

            const res = await axios.post(
                "https://ipfs.infura.io:5001/api/v0/add",
                formData, {
                    headers: {
                        Authorization: "Basic MkRDUm14VDFwV3FWYkR3cHRzcWdKcVQ5V3d0OmQyYWI4NWMyYmE0ZmRmZjlhODRkOWVlMTc3MDM3OGE2",
                        // "User-Agent": "",
                        // 'Content-Length': file.length,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res === null) {
                throw Error("Failed to add data to IPFS");
            }
            return { ipfsHash: res.data.Hash, data: data };
        } catch (er) {
            console.log(er);
            throw new Error("Failed to add data to IPFS");
        }
    }

    async getIpfsMetadata(hash) {
        try {
            const metadata = `https://niftron.infura-ipfs.io/ipfs/${hash}`;
            const res = await axios.get(metadata);
            if (res === null) {
                throw Error("Failed to get data from IPFS");
            }
            return res.data;
            
        } catch (error) {
            console.log(error);
            throw new Error("Failed to get data from IPFS");
        }
    }
}