export const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
    isAuthRequired: false
  },
  {
    id: 2,
    name: "Explore",
    links: "/explore",
    isAuthRequired: false
  },
  // {
  //   id: 3,
  //   name: "Activity",
  //   links: "/activity",
  //   isAuthRequired: false
  // },
  // {
  //   id: 4,
  //   name: "Community",
  //   isAuthRequired: false,
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Blog",
  //       links: "/blog",
  //       isAuthRequired: false,
  //     },
  //     {
  //       id: 2,
  //       sub: "Blog Details",
  //       links: "/blog-details",
  //       isAuthRequired: false,
  //     },
  //     {
  //       id: 3,
  //       sub: "Help Center",
  //       links: "/help-center",
  //       isAuthRequired: false,
  //     },
  //   ],
  // },
  {
    id: 5,
    name: "Create Item",
    links: "/create-item",
    isAuthRequired: true,
  },
  // {
  //   id: 6,
  //   name: "More",
  //   isAuthRequired: false,
  //   links: "#",
  //   namesub: [
      // {
      //   id: 1,
      //   sub: "Wallet Connect",
      //   links: "/wallet-connect",
      //   isAuthRequired: false,
      // },
      // {
      //   id: 2,
      //   sub: "Contact",
      //   links: "/contact",
      //   isAuthRequired: false,
      // },
      // {
      //   id: 3,
      //   sub: "Ranking",
      //   links: "/ranking",
      //   isAuthRequired: false,
      // },
      // {
      //   id: 4,
      //   sub: "FAQ",
      //   links: "/faq",
      //   isAuthRequired: false,
      // },
    // ],
  // },
  {
    id: 7,
    name: "Wallet Connect",
    isMain: true,
    links: "#",
    namesub: [
      {
        id: 1,
        isProfile: true,
        sub: "My Profile",
        links: "/my-profile",
      },
      {
        id: 2,
        sub: "Edit Profile",
        links: "/edit-profile",
      },
      {
        id: 3,
        isLogout: true,
        sub: "Logout",
        links: "/logout",
      },
    ],  
  }
];

