import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import routes from "../pages/index";
import { selectUser, logout } from "../redux/reducers/userReducer.ts";
import { authenticateToken } from "../utils/utils.ts";

const App = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const accessToken = user.accessToken;

  useEffect(() => {
    try {
      accessToken && authenticateToken(accessToken)  
    } catch (error) {
      dispatch(logout());
    }
  }, [accessToken, dispatch])

  return (
    <Routes>
      {routes.map((data, index) => (
        <Route
          onUpdate={() => window.scrollTo(0, 0)}
          exact={true}
          path={data.path}
          element={data.component}
          key={index}
        />
      ))}
    </Routes>
  );
}

export default App;
