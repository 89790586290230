import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails, selectUser, editUserProfileDetails } from "../redux/reducers/userReducer.ts";
import { authGuard } from "../utils/utils.ts";
import {
  getUserService,
  getUserByAddressPublicService,
  updateUserService,
} from "../services/user.service.ts";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import SnackAction from "../components/snackAction/SnackAction";

const EditProfilePage = () => {
  const user = useSelector(selectUser);
  const isUserLoggedIn = user.accessToken !== null;
  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    customUrl: "",
    email: "",
    bio: "",
    twitterUsername: "",
    portfolioSite: "",
    profileImage: "",
    isVerifyReqCreated: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [notify, setNotify] = useState();
  const [displayNotification, setDisplayNotification] = useState(false);
  const dispatch = useDispatch();

  const updateUserDetails = async () => {
    const userData = (await getUserByAddressPublicService(user.address)).user;
    dispatch(setUserDetails(userData));
  }

  useEffect(() => {
    authGuard(isUserLoggedIn, navigate);
  }, [isUserLoggedIn, navigate]);

  const handleUserProfileCreation = useCallback(async () => {
    if (user.accessToken) {
      setIsLoading(true);
      try {
        const userResponse = await getUserService(user.accessToken);
        const isUserPresent = userResponse.user ? true : false;

        if (isUserPresent) {
          const details = {
            displayName: userResponse.user.displayName,
            customUrl: userResponse.user.customUrl,
            email: userResponse.user.email,
            bio: userResponse.user.bio,
            twitterUsername: userResponse.user.twitterUsername,
            portfolioSite: userResponse.user.portfolioSite,
            profileImage: userResponse.user.profileImage,
            isVerifyReqCreated: userResponse.user.isVerifyReqCreated,
          };

          setUserProfileData(details);
          dispatch(editUserProfileDetails(details));
        }
        await updateUserDetails();
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    handleUserProfileCreation();
  }, [handleUserProfileCreation]);

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateUserService(userProfileData, user.accessToken);
      setNotify("Profile updated successfully");
    } catch (error) {
      console.log(error);
      setNotify("Profile update failed");
    } finally {
      setIsLoading(false);
      setDisplayNotification(true);
    }
  };

  const handleProfileImageUpdate = async (file) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result;
      setUserProfileData({ ...userProfileData, profileImage: base64String });
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteProfilePic = () => {
    setUserProfileData({ ...userProfileData, profileImage: "" });
  };

  const handleUserVerification = async (event) => {
    event.preventDefault();
    if (
      !userProfileData.displayName ||
      !userProfileData.customUrl ||
      !userProfileData.email ||
      !userProfileData.bio ||
      !userProfileData.twitterUsername ||
      !userProfileData.portfolioSite
    ) {
      setNotify("Please fill all the fields");
      setDisplayNotification(true);
      return;
    }
    setUserProfileData({ ...userProfileData, isVerifyReqCreated: true });
    setIsLoading(true);
    try {
      await updateUserService({ ...userProfileData, isVerifyReqCreated: true }, user.accessToken);
      setNotify("Verification sent successfully");
    } catch (error) {
      console.log(error);
      setNotify("Verification failed");
    } finally {
      setIsLoading(false);
      setDisplayNotification(true);
    }
  };

  return (
    <StyledContainer>
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <Header />
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">Edit Profile</h1>
                  </div>
                  <div className="breadcrumbs style2">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="#">Pages</Link>
                      </li>
                      <li>Edit Profile</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="tf-create-item tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                  <div className="sc-card-profile text-center">
                    <div className="card-media">
                      {userProfileData.profileImage && (
                        <img
                          id="profileimg"
                          src={userProfileData.profileImage}
                          alt="Axies"
                        />
                      )}
                    </div>
                    <div id="upload-profile">
                      <Link to="#" className="btn-upload">
                        Upload New Photo{" "}
                      </Link>
                      <input
                        id="tf-upload-img"
                        type="file"
                        name="profile"
                        onChange={(e) =>
                          handleProfileImageUpdate(e.target.files[0])
                        }
                      />
                    </div>
                    <Link
                      to="#"
                      className="btn-upload style2"
                      onClick={handleDeleteProfilePic}
                    >
                      Delete
                    </Link>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                  <div className="form-upload-profile">
                    {/* <h4 className="title-create-item">
                      Choice your Cover image
                    </h4>
                    <div className="option-profile clearfix">
                      <form action="#">
                        <label className="handleProfileImageUpdate">
                          <input
                            type="file"
                            className="inputfile form-control"
                            name="file"
                          />
                        </label>
                      </form>
                      <div className="image">
                        <img src={bg1} alt="Axies" />
                      </div>
                      <div className="image style2">
                        <img src={bg2} alt="Axies" />
                      </div>
                    </div> */}

                    <form
                      action="#"
                      className="form-profile"
                      onSubmit={onHandleSubmit}
                    >
                      <div className="form-infor-profile">
                        <div className="info-account">
                          <h4 className="title-create-item">Account info</h4>
                          <fieldset>
                            <h4 className="title-infor-account">
                              Display name *
                            </h4>
                            <input
                              required
                              type="text"
                              placeholder="Display name"
                              value={userProfileData.displayName}
                              onChange={(e) => {
                                setUserProfileData({
                                  ...userProfileData,
                                  displayName: e.target.value,
                                });
                              }}
                            />
                          </fieldset>
                          <fieldset>
                            <h4 className="title-infor-account">Custom URL</h4>
                            <input
                              type="text"
                              placeholder="Axies.Trista Francis.com/"
                              value={userProfileData.customUrl}
                              onChange={(e) => {
                                setUserProfileData({
                                  ...userProfileData,
                                  customUrl: e.target.value,
                                });
                              }}
                            />
                          </fieldset>
                          <fieldset>
                            <h4 className="title-infor-account">Email *</h4>
                            <input
                              required
                              type="email"
                              placeholder="Enter your email"
                              value={userProfileData.email}
                              onChange={(e) => {
                                setUserProfileData({
                                  ...userProfileData,
                                  email: e.target.value,
                                });
                              }}
                            />
                          </fieldset>
                          <fieldset>
                            <h4 className="title-infor-account">Bio</h4>
                            <textarea
                              tabIndex="4"
                              rows="5"
                              placeholder="Enter your bio"
                              value={userProfileData.bio}
                              onChange={(e) => {
                                setUserProfileData({
                                  ...userProfileData,
                                  bio: e.target.value,
                                });
                              }}
                            ></textarea>
                          </fieldset>
                        </div>
                        <div className="info-social">
                          <h4 className="title-create-item">
                            Your Social media
                          </h4>
                          {/* <fieldset>
                            <h4 className="title-infor-account">Facebook</h4>
                            <input
                              type="text"
                              placeholder="Facebook username"
                            />
                             <Link to="#" className="connect">
                              <i className="fab fa-facebook"></i>Connect to face
                              book
                            </Link> 
                          </fieldset> */}
                          <fieldset>
                            <h4 className="title-infor-account">Twitter</h4>
                            <input
                              type="text"
                              placeholder="Twitter username"
                              value={userProfileData.twitterUsername}
                              onChange={(e) => {
                                setUserProfileData({
                                  ...userProfileData,
                                  twitterUsername: e.target.value,
                                });
                              }}
                            />
                          </fieldset>

                          <fieldset>
                            <h4 className="title-infor-account">
                              Personal site or portfolio
                            </h4>
                            <input
                              type="text"
                              placeholder="Personal site or portfolio"
                              value={userProfileData.portfolioSite}
                              onChange={(e) => {
                                setUserProfileData({
                                  ...userProfileData,
                                  portfolioSite: e.target.value,
                                });
                              }}
                            />
                          </fieldset>

                          <fieldset>
                            <h4 className="title-infor-account">
                              Verification
                            </h4>
                            {userProfileData.isVerifyReqCreated ? (
                              <h4 className="title-infor-account">
                                <strong>(Request sent!)</strong>
                              </h4>
                            ) : (
                              <button
                                className="tf-button-submit mg-t-15"
                                onClick={handleUserVerification}
                              >
                                Get verified
                              </button>
                            )}
                          </fieldset>
                        </div>
                      </div>
                      <button
                        className="tf-button-submit mg-t-15"
                        type="submit"
                      >
                        Update Profile
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
      <Snackbar
        className="snackbar-text"
        open={displayNotification}
        autoHideDuration={6000}
        onClose={() => {
          setDisplayNotification(false);
        }}
        message={notify}
        action={<SnackAction handleClose={setDisplayNotification} />}
      />
    </StyledContainer>
  );
};

export default EditProfilePage;

const StyledContainer = styled.div`
  .loading-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    > * {
      color: #FFF !important;
    }
  }

  .snackbar-text > * {
    font-size: 1.5rem !important;
  }
`;
