import React, { useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import ExploreItem from "./ExploreItem";
// import { nftCategories } from "../../../utils/utils.ts";
import _ from "lodash";

const Explore = ({
  collections,
  nftsBuyNow,
  // nftsOnAuction,
  visible,
  setVisible,
  // setTriggerTimeRefetch,
  setTriggerFixedRefetch,
}) => {
  const uniqueCollections =
    Array.from(new Set(collections.map((v) => v.name))).map((v) => ({ name: v }));

  const [checked, setChecked] = useState(
    // [{ name: 'Buy Now' }, { name: 'On Auction' }, ...nftCategories, ...uniqueCollections].map((c, i) => (
    [{ name: 'Buy Now' }, ...uniqueCollections].map((c, i) => (
      i === 0 ? { name: c.name, checked: true } : { name: c.name || c, checked: false }
    ))
  );

  const onCheckboxChange = (index) => {
    const tempChecked = [...checked];
    tempChecked[index].checked = !checked[index].checked;
    setChecked(tempChecked);
  };

  const filterNFTs = () => {
    const allNfts = [...nftsBuyNow, /*...nftsOnAuction*/];
    let filteredNfts = [];

    if (checked.some((c) => c.checked === true)) {
      const currentlyChecked = checked.filter((c) => c.checked === true);
      currentlyChecked.forEach((c) => {
        switch (c.name) {
          case 'Buy Now': {
            filteredNfts = filteredNfts.concat(allNfts.filter((n) => n.method === 'Fixed Price'));
            break;
          }
          // case 'On Auction': {
          //   filteredNfts = filteredNfts.concat(allNfts.filter((n) => n.method === 'Time Auctions'));
          //   break;
          // }
          default: {
            break;
          }
        }
      });

      uniqueCollections.forEach((c) => {
        if (currentlyChecked.find((n) => n.name === c.name)) {
          filteredNfts = filteredNfts.concat(allNfts.filter((n) => n.collectionDetails.name === c.name));
        }
      });

      // nftCategories.forEach((c) => {
      //   if (currentlyChecked.find((n) => n.name === c)) {
      //     filteredNfts = filteredNfts.concat(allNfts.filter((n) => n.category === c));
      //   }
      // });
    }

    filteredNfts = _.uniqBy(filteredNfts, 'title');
    return filteredNfts;
  };

  return (
    <section className="tf-explore tf-section">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-12">
            <div id="side-bar" className="side-bar style-3">
              <div className="widget widget-category mgbt-24 boder-bt">
                <div className="content-wg-category">
                  <Accordion title="Explore" show={true}>
                    <form action="#">
                      {['Buy Now'].map((itm, i) => (
                        // {['Buy Now', 'On Auction'].map((itm, i) => (
                        <div key={i}>
                          <label>
                            {itm}
                            <input
                              type="checkbox"
                              name={itm}
                              checked={checked[i].checked}
                              onChange={() => onCheckboxChange(i)}
                            />
                            <span className="btn-checkbox"></span>
                          </label>
                          <br />
                        </div>
                      ))}
                    </form>
                  </Accordion>
                </div>
              </div>
              {/* <div className="widget widget-category mgbt-24 boder-bt">
                <div className="content-wg-category">
                  <Accordion title="Category" show={true}>
                    <form action="#">
                      {nftCategories.map((itm, i) => (
                        <div key={i}>
                          <label>
                            {itm}
                            <input
                              type="checkbox"
                              name={itm}
                              checked={checked[i + 2].checked}
                              onChange={() => onCheckboxChange(i + 2)}
                            />
                            <span className="btn-checkbox"></span>
                          </label>
                          <br />
                        </div>
                      ))}
                    </form>
                  </Accordion>
                </div>
              </div> */}
              <div className="widget widget-category mgbt-24 boder-bt">
                <div className="content-wg-category">
                  <Accordion title="Collections" show={true}>
                    <form action="#">
                      {uniqueCollections.map((itm, i) => (
                        <div key={i}>
                          <label>
                            {itm.name}
                            <input
                              type="checkbox"
                              name={itm.name}
                              checked={checked[i + 1].checked}
                              onChange={() => onCheckboxChange(i + 1)}
                              // checked={checked[i + 9].checked}
                              // onChange={() => onCheckboxChange(i + 9)}
                            />
                            <span className="btn-checkbox"></span>
                          </label>
                          <br />
                        </div>
                      ))}
                    </form>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-9 col-lg-9 col-md-12">
            <ExploreItem
              visible={visible}
              setVisible={setVisible}
              displayingNfts={filterNFTs()}
              // setTriggerTimeRefetch={setTriggerTimeRefetch}
              setTriggerFixedRefetch={setTriggerFixedRefetch}
            />
          </div>
        </div>
      </div>
    </section >
  );
};

export default Explore;
