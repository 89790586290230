import { Apis } from "../apis/apis";
import { postRequest, getRequest, patchRequest } from "./../utils/requests";

export const createUserService = async (requestBody: any, token: string) =>
  await postRequest(Apis.USER, requestBody, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getUserService = async (token: string) =>
  await getRequest(Apis.USER, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getUserByAddressPublicService = async (address: string) =>
  await getRequest(`${Apis.USER}/address/public/${address}`, null)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const updateUserService = async (requestBody: any, token: string) =>
  await patchRequest(Apis.USER, requestBody, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getUserFollowers = async (token: string, walletAddress: string) =>
  await getRequest(`${Apis.USER}/followers/address/${walletAddress}`, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getUserFollowings = async (token: string, walletAddress: string) =>
  await getRequest(`${Apis.USER}/followings/address/${walletAddress}`, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getFilteredUsersBySearchPattern = async (searchPattern: string) =>
  await getRequest(`${Apis.USER}/filter/name/${searchPattern}`, null)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const getTopSellers = async () =>
  await getRequest(`${Apis.USER}s/top-sellers`, null)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const handleUserFollow = async (requestBody: any, token: string) =>
  await patchRequest(`${Apis.USER}/follower/add`, requestBody, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

export const handleUserUnFollow = async (requestBody: any, token: string) =>
  await patchRequest(`${Apis.USER}/follower/remove`, requestBody, token)
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
