import { IconButton } from '@material-ui/core'
import { Fragment } from 'react'
import CloseIcon from '@material-ui/icons/Close'

const SnackAction = (props) => {
    const { handleClose } = props

    return (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    )
}

export default SnackAction
