import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Header from "../components/header/Header";
import { ProfileTabs } from "../components/profile/ProfileTabs/ProfileTabs";
import truncate from "truncate";
import { useDispatch, useSelector } from "react-redux";
import { authGuard } from "../utils/utils.ts";

import { useNavigate, useParams } from "react-router-dom";
import { CollectionService } from '../services/collections.service';
import { NFTService } from '../services/nft.service';
import { selectUser, selectUserDetails, addFollowing, removeFollowing } from "../redux/reducers/userReducer.ts";
import { CircularProgress, Snackbar } from "@material-ui/core";
import {
  getUserByAddressPublicService,
  getUserFollowers,
  getUserFollowings,
  handleUserUnFollow,
  handleUserFollow,
} from "../services/user.service.ts";
import UserFollowersModal from "../components/followers/UserFollowers";
import SnackAction from "../components/snackAction/SnackAction";
import { ActivityType } from "../enum/Activity.ts";
import { createActivityService, getUserActivitiesService } from "../services/activity.service.ts";
import { ContactUsService } from "../services/contact-us.service";
import Footer from "../components/footer/Footer";

const UserProfile = () => {
  const currentUser = useSelector(selectUser);
  const currentUserDetails = useSelector(selectUserDetails);
  const walletAddress = currentUser?.address;

  const navigate = useNavigate();
  const nftService = useMemo(() => new NFTService(), []);
  const collectionService = useMemo(() => new CollectionService(), []);
  const contactUsService = useMemo(() => new ContactUsService(), []);
  const isUserLoggedIn = currentUser.accessToken !== null;
  const accessToken = currentUser.accessToken;

  const [openModal, setOpenModal] = useState(false);
  const [userFollowers, setUserFollowers] = useState([]);
  const [userFollowings, setUserFollowings] = useState([]);
  const [isUserFollowers, setIsUserFollowers] = useState(false);

  const { address } = useParams();
  const [userDetails, setUserDetails] = useState();
  const [collections, setCollections] = useState();
  const [nftsOnSale, setNftsOnSale] = useState();
  const [userActivities, setUserActivities] = useState([]);
  const [ownedNfts, setOwnedNfts] = useState();
  const [createdNfts, setCreatedNfts] = useState();
  const [userMessages, setUserMessages] = useState();
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isUserFollower, setIsUserFollower] = useState(false);
  const [followingUserId, setFollowingUserId] = useState(null);
  const [isUserFollowerLoading, setIsUserFollowerLoading] = useState(false);
  const defaultUserProfileImage = "https://niftron.infura-ipfs.io/ipfs/QmcFee3QWZLin6tkHJvDC2o3pXQcSnoJHWDX3k3S5a43tQ";

  const dispatch = useDispatch();
  const [notify, setNotify] = useState();
  const [displayNotification, setDisplayNotification] = useState(false);

  useEffect(() => {
    if (!currentUserDetails?._id && (address === currentUser?.address)) {
      navigate(`/edit-profile`);
    }
  }, [address, currentUser.address, currentUserDetails, navigate]);

  const handleNavigateToEditProfile = () => {
    navigate(`/edit-profile`);
  };

  const handleOpenFollowerModal = () => {
    setOpenModal(true);
    setIsUserFollowers(true);
  };

  const handleOpenFollowingModal = () => {
    setOpenModal(true);
    setIsUserFollowers(false);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    authGuard(isUserLoggedIn, navigate);
  }, [isUserLoggedIn, navigate]);

  useEffect(() => {
    const getInitialData = async () => {
      if (currentUserDetails) {
        const userData = (await getUserByAddressPublicService(address)).user;
        setUserDetails(userData);
      }
    };

    getInitialData();
  }, [address, currentUserDetails]);

  useEffect(() => {
    const getInitialData = async () => {
      if (currentUserDetails) {
        const messageData = (await contactUsService.getUserMessagees(address)).messages;
        setUserMessages(messageData);
      }
    };

    getInitialData();
  }, [address, currentUserDetails, contactUsService]);

  useEffect(() => {
    try {
      const getInitialData = async () => {
        if (currentUserDetails) {
          setIsFetchingData(true);
          const collectionsData = (await collectionService.getUserCollections(address)).collections;
          const nftData = (await nftService.getNFTs('true')).nfts;
          // const userActivitiesData = (await getUserActivitiesService(currentUserDetails._id)).activities ?? [];
          // console.log('user activities details', userActivitiesData);
          // setUserActivities(userActivitiesData);
          setCreatedNfts(nftData.filter((nft) => nft?.creator.toLowerCase() === address?.toLowerCase() && !nft.isMinted)); 
          setOwnedNfts(nftData.filter((nft) => nft?.owner.toLowerCase() === address?.toLowerCase() && !nft.putOnMarket && nft.isMinted));
          setNftsOnSale(nftData.filter((nft) => nft?.owner.toLowerCase() === address?.toLowerCase() && nft.putOnMarket && nft.isMinted));
          setCollections(collectionsData);
          setIsFetchingData(false);
        }
      }

      getInitialData();
    } catch (error) {
      console.log(error);
      setNotify("Something went wrong when fetching nft details");
      setDisplayNotification(true);
    }

  }, [address, collectionService, nftService, triggerRefetch, currentUserDetails]);

  useEffect(() => {
    try {
      const getUserFollowerFollowingData = async () => {
        // if (!accessToken) throw new Error('No access token');
        // const userFollowersData = (await getUserFollowers(accessToken, address)).followers;
        // const userFollowingData = (await getUserFollowings(accessToken, address)).followings;

        // setUserFollowers(userFollowersData);
        // setUserFollowings(userFollowingData);
      };

      getUserFollowerFollowingData();
    } catch (error) {
      console.log(error);
      setNotify("Something went wrong when fetching user followers and followings");
      setDisplayNotification(true);
    }
  }, [accessToken, address]);

  useEffect(() => {
    const userFollowerData = currentUserDetails?.followings?.find((user) => user.address.toLowerCase() === address.toLowerCase());
    if (userFollowerData) setIsUserFollower(true);
  }, [currentUserDetails, address]);

  useEffect(() => {
    if (userDetails) setFollowingUserId(userDetails._id);
  }, [userDetails])

  const handleFollowUser = async () => {
    if (!followingUserId) throw new Error('No following user id');

    const requestBody = { "following": followingUserId };
    setIsUserFollowerLoading(true);

    if (isUserFollower) {
      await handleUserUnFollow(requestBody, accessToken).then(async () => {
        setIsUserFollower(false);
        dispatch(removeFollowing({ userId: followingUserId }));
        removeFollower(currentUserDetails._id);
        setNotify("Success: User unfollowed 👍");

        const userActivityRequestBody = {
          fromUser: currentUserDetails._id,
          toUser: followingUserId,
          type: ActivityType.UserUnFollowing
        };

        await createActivityService(userActivityRequestBody, accessToken)
          .catch(error => { throw new Error(error) });

      }).catch((error) => {
        console.log('Error', error);
        setNotify("Something went wrong when unfollowing user");

      }).finally(() => {
        setIsUserFollowerLoading(false);
        setDisplayNotification(true);
      });

    } else {
      await handleUserFollow(requestBody, accessToken).then(async () => {
        setIsUserFollower(true);
        addFollower({ address: currentUserDetails.address, name: currentUserDetails.displayName, userId: currentUserDetails._id, profilePhoto: currentUserDetails.profileImage });
        dispatch(addFollowing({
          address,
          userId: followingUserId,
          name: userDetails.name,
          profilePhoto: userDetails.profilePhoto
        }));
        setNotify("Success: User followed 👍");

        const userActivityRequestBody = {
          fromUser: currentUserDetails._id,
          toUser: followingUserId,
          type: ActivityType.UserFollowing
        };

        await createActivityService(userActivityRequestBody, accessToken)
          .catch(error => { throw new Error(error) });
      })
        .catch((error) => {
          console.log('Error', error);
          setNotify("Something went wrong when following user");
        }).finally(() => {
          setIsUserFollowerLoading(false);
          setDisplayNotification(true);
        });
    }
  }

  const removeFollower = (userId) => {
    let userFollowersUpdate = userFollowers.filter((user) => user.userId !== userId);
    setUserFollowers(userFollowersUpdate);
  };

  const addFollower = ({ address, name, profilePhoto, userId }) => {
    let userFollowersUpdated = [...userFollowers];
    userFollowersUpdated.push({ address, name, profilePhoto, userId });
    setUserFollowers(userFollowersUpdated);
  };

  return (
    <ContainerStyled>
      <Header />
      {!isFetchingData && userDetails && userMessages ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">User Profile</h1>
                  </div>
                  <br />

                  <div className="profile-img">
                    <img src={userDetails.profileImage ?? defaultUserProfileImage} alt="dp" />
                  </div>
                  <br />
                  <br />

                  <div className="breadcrumbs style2 user-details-area">
                    <ul>
                      <li style={{ fontWeight: "bold" }}>{userDetails?.displayName} &nbsp;</li>
                    </ul>
                    <ul className="wallet-address">
                      <li>{truncate(userDetails?.address, 20)}&nbsp;</li>
                    </ul>
                    <ul>
                      {userDetails?.twitterUsername && <li style={{ fontWeight: "bold" }}>&nbsp;@{userDetails.twitterUsername}</li>}
                    </ul>
                  </div>
                  <br />
                  <br />

                  <div className="breadcrumbs style2 followers">
                    <ul>
                      <li>
                        <p onClick={handleOpenFollowerModal}>{userFollowers.length} followers &nbsp;</p>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        <p onClick={handleOpenFollowingModal}>{userFollowings.length} following &nbsp;</p>
                      </li>
                    </ul>
                  </div>
                  <br />
                  <br />

                  {userDetails?.address?.toLowerCase() === walletAddress?.toLowerCase() && (
                    <div className="breadcrumbs style2">
                      <button
                        className="tf-button-submit btn-primary-edit"
                        onClick={handleNavigateToEditProfile}
                      >
                        Edit
                      </button>
                    </div>
                  )}

                  {(userDetails && userDetails?.address.toLowerCase() !== walletAddress?.toLowerCase()) && (
                    <div className="breadcrumbs style2">
                      <button
                        className="tf-button-submit btn-primary-edit"
                        onClick={handleFollowUser}
                      >
                        {(isUserFollowerLoading) ? <CircularProgress size={20} /> : (isUserFollower ? "Unfollow" : "Follow")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <UserFollowersModal
            onHide={onCloseModal}
            show={openModal}
            isUserFollowers={isUserFollowers}
            data={isUserFollowers ? userFollowers : userFollowings}
          />
          <ProfileTabs
            userDetails={userDetails}
            collections={collections}
            nftsOnSale={nftsOnSale}
            ownedNfts={ownedNfts}
            createdNfts={createdNfts}
            userActivities={userActivities}
            userMessages={userMessages}
            setTriggerRefetch={setTriggerRefetch}
          />
        </>
      ) : (
        <ProgressContainer>
          <CircularProgress color="primary" />
        </ProgressContainer>
      )}

      <Footer />
      <Snackbar
        className="snackbar-text"
        open={displayNotification}
        autoHideDuration={6000}
        onClose={() => {
          setDisplayNotification(false);
        }}
        message={notify}
        action={<SnackAction handleClose={setDisplayNotification} />}
      />
    </ContainerStyled>
  );
};

export default UserProfile;

const ContainerStyled = styled.div`
  .wallet-address {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }

  .user-details-area {
    flex-wrap: wrap;
  }

  .followers {
    p {
      cursor: pointer;
      color: #ccc;
      font-weight: bold;
      font-size: 1.6rem;
      &:hover {
        color: #fff;
      }
    }
  }
  .btn-primary-edit {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    padding: 1rem 2rem !important;
  }
  .profile-img {
    display: flex;
    justify-content: center;
    > img {
      border-radius: 50%;
      width: 150px;
      object-fit: contain;
    }
  }

  .snackbar-text > * {
    font-size: 1.5rem !important;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  > * {
    color: #FFF !important;
  }
`;