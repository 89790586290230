import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Collection from "../components/layouts/collection/Collection";
import { CollectionService } from "../services/collections.service";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const CollectionPage = () => {
  const { id } = useParams();
  const collectionService = useMemo(() => new CollectionService(), []);

  const [collectionData, setCollectionData] = useState();
  const [nftCount, setNftCount] = useState();
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getInitialData = async () => {
      const cData = (await collectionService.getCollection(id)).collection;
      setNftCount(cData.nfts.length);
      setCollectionData(cData);
    };

    getInitialData();
  }, [collectionService, id, triggerRefetch]);

  const navigateToHome = () => {
    navigate("/");
  }

  return (
    <div className="auctions">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Collection</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Collection</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {nftCount === undefined && (
        <ProgressContainer>
          <CircularProgress color="primary" />
        </ProgressContainer>
      )}
      {nftCount > 0 && collectionData ? (
        <Collection setTriggerRefetch={setTriggerRefetch} data={collectionData} />
      ) : nftCount === 0 && (
        <NotFoundStyledContainer>
          <h4>No items found</h4>
          <p>Come back soon! Or try to browse something for you on our marketplace</p>
          <button onClick={navigateToHome}>Browse marketplace</button>
        </NotFoundStyledContainer>
      )}
      <Footer />
    </div>
  );
};

export default CollectionPage;

const ProgressContainer = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: #14141F;

  > * {
    color: #FFF !important;
  }
`;

const NotFoundStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  
  h4 { 
    padding: 1rem;
  }

  p {
    color: grey;
    padding: 1rem;
    font-size: 1.6rem;
  }

  button {
    margin: 1rem;
    padding: 1.5rem 2rem;

    &:hover {
      color: #fff;
      background-color: #4032ff;
    }
  }
`;
