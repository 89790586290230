import React, { useEffect, useMemo, useState } from "react";
import Footer from "../components/footer/Footer";
import Banner from "../components/layouts/home/Banner";
import LiveAuction from "../components/layouts/home/LiveAuction";
import TopSeller from "../components/layouts/home/TopSeller";
import TodayPicks from "../components/layouts/home/TodayPicks";
import PopularCollection from "../components/layouts/PopularCollection";
import Create from "../components/layouts/Create";
import Header from "../components/header/Header";
import { NFTService } from "../services/nft.service";
import { CollectionService } from "../services/collections.service";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import _ from "lodash";
import { getTopSellers } from "../services/user.service.ts";

const HomePage = () => {
  const collectionsService = useMemo(() => new CollectionService(), []);
  const nftService = useMemo(() => new NFTService(), []);
  const [popularCollections, setPopularCollections] = useState();
  const [uniqueNfts, setUniqueNfts] = useState();
  // const [liveAuctions, setLiveAuctions] = useState();
  const [todaysPicks, setTodaysPicks] = useState();
  const [topSellers, setTopSellers] = useState();

  // const [triggerTimeRefetch, setTriggerTimeRefetch] = useState(false);
  const [triggerFixedRefetch, setTriggerFixedRefetch] = useState(false);

  useEffect(() => {
    const getInitialData = async () => {
      const lData = (await getTopSellers()).topSellers;
      setTopSellers(lData);
    };

    getInitialData();
  }, []);

  useEffect(() => {
    const getInitialData = async () => {
      const lData = (await nftService.getNFTs("true")).nfts;
      // setUniqueNfts(_.uniqBy(lData, 'category'));
      setUniqueNfts(lData);
    };

    getInitialData();
  }, [nftService]);

  // useEffect(() => {
  //   const getInitialData = async () => {
  //     const lData = (await nftService.getNFTsOfType('Time Auctions')).nfts;
  //     setLiveAuctions(lData);
  //   };

  //   getInitialData();
  // }, [nftService, triggerTimeRefetch]);

  useEffect(() => {
    const getInitialData = async () => {
      const tData = (await nftService.getNFTsOfType('Fixed Price')).nfts;
      setTodaysPicks(tData);
    };

    getInitialData();
  }, [nftService, triggerFixedRefetch]);

  useEffect(() => {
    const getInitialData = async () => {
      const pData = (await collectionsService.getPopularCollections()).collections;
      setPopularCollections(pData);
    };

    getInitialData();
  }, [collectionsService]);

  return (
    <div className="home-4">
      <Header />
      {/* {liveAuctions && todaysPicks && popularCollections && uniqueNfts && topSellers ? ( */}
      {todaysPicks && popularCollections && uniqueNfts && topSellers ? (
        <>
          <Banner nfts={uniqueNfts} />
          {/* <LiveAuction setTriggerTimeRefetch={setTriggerTimeRefetch} data={liveAuctions} /> */}
          <TopSeller data={topSellers} />
          <TodayPicks setTriggerFixedRefetch={setTriggerFixedRefetch} data={todaysPicks} />
          <PopularCollection data={popularCollections} />
        </>
      ) : (
        <ProgressContainer>
          <CircularProgress color="primary" />
        </ProgressContainer>
      )}
      {/* <Create /> */}
      <Footer />
    </div>
  );
};

export default HomePage;

const ProgressContainer = styled.div`
  display: flex;
  height: 350px;
  align-items: center;
  justify-content: center;
  background-color: #14141F;

  > * {
    color: #FFF !important;
  }
`;