import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Countdown from "react-countdown";
import { selectUser } from "../../../redux/reducers/userReducer.ts";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import UserActivty from "../UserActivty/UserActivty";
import CreateCollection from "../../createcollection/CreateCollection";
import { create } from "ipfs-http-client";
import { IPFS } from "../../../apis/apis.ts";
import { authGuard } from "../../../utils/utils.ts";
import { CollectionService } from "../../../services/collections.service";
import { NFTService } from "../../../services/nft.service";
import { Dropdown } from "react-bootstrap";
import EditNFTModal from "../../layouts/EditNFTModal";
import UserMessage from "../UserMessage/UserMessage";
import { ActivityType } from "../../../enum/Activity";
import ReactPlayer from "react-player";

const client = create(IPFS);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const ProfileTabs = (props) => {
  const {
    userDetails,
    collections,
    nftsOnSale,
    ownedNfts,
    createdNfts,
    userActivities,
    userMessages,
    setTriggerRefetch,
  } = props;

  const [value, setValue] = useState(0);
  const [onSaleCount, setOnSaleCount] = useState(undefined);
  const [ownedCount, setOwnedCount] = useState(undefined);
  const [createdCount, setCreatedCount] = useState(undefined);
  const [collectionCount, setCollectionCount] = useState(undefined);

  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [newCollectionLogo, setNewCollectionLogo] = useState();
  const [newCollectionName, setNewCollectionName] = useState();
  const [newCollectionDesc, setNewCollectionDesc] = useState();
  const [newCollectionSymbol, setNewCollectionSymbol] = useState();
  const [newCollectionUrl, setNewCollectionUrl] = useState();
  const [createCollectionErrorMessage, setCreateCollectionErrorMessage] =
    useState("");

  const [toEditNft, setToEditNft] = useState();
  const [toEditMethod, setToEditMethod] = useState("Fixed Price");
  const [toEditPrice, setToEditPrice] = useState();
  const [toEditMinBid, setToEditMinBid] = useState();
  const [toEditStartingDate, setToEditStartingDate] = useState();
  const [toEditEndingDate, setToEditEndingDate] = useState();
  const [showEditNFTModal, setShowEditNFTModal] = useState(false);
  const defaultUserProfileImage = "https://niftron.infura-ipfs.io/ipfs/QmcFee3QWZLin6tkHJvDC2o3pXQcSnoJHWDX3k3S5a43tQ";

  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const isUserLoggedIn = user.accessToken !== null;
  const nftService = useMemo(() => new NFTService(), []);
  const collectionService = useMemo(() => new CollectionService(), []);

  const activityIcon = useMemo(() => ({
    Person: "icon-1",
    Nft: 'icon-2',
    Heart: "icon-4",
    Other: "icon-3",
  }), []);

  useEffect(() => {
    authGuard(isUserLoggedIn, navigate);
    setCollectionCount(collections && collections.length);
    setOnSaleCount(nftsOnSale && nftsOnSale.length);
    setOwnedCount(ownedNfts && ownedNfts.length);
    setCreatedCount(createdNfts && createdNfts.length);
  }, [
    collections,
    nftsOnSale,
    ownedNfts,
    createdNfts,
    isUserLoggedIn,
    navigate,
  ]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const validateCreateCollection = () => {
    let validInput = true;
    if (
      !newCollectionLogo ||
      !newCollectionDesc ||
      !newCollectionUrl ||
      !newCollectionSymbol ||
      !newCollectionName
    ) {
      validInput = false;
    }

    return validInput;
  };

  // useEffect(() => {
  //   console.log(user)
  //   console.log(userDetails)

  //   console.log(
  //     nftsOnSale,
  //   )
  //   console.log( ownedNfts)
  //   console.log(
  //   createdNfts,
  //   )
  // }, [])

  const handleCreateCollection = async () => {
    setCreateCollectionErrorMessage("");
    setTriggerRefetch(false);
    const validInput = validateCreateCollection();
    if (validInput) {
      const body = {
        logo: newCollectionLogo,
        name: newCollectionName,
        description: newCollectionDesc,
        symbol: newCollectionSymbol,
        url: newCollectionUrl,
        creator: user.address,
        owner: user.address,
        nfts: [],
      };

      try {
        await collectionService.createCollection(body);
        setShowCreateCollection(false);
        const collectionsData = (await collectionService.getUserCollections(user.address)).collections;
        setCollectionCount(collectionsData && collectionsData.length);
      } catch (err) {
        setCreateCollectionErrorMessage(err.response.data.collection);
      } finally {
        setTriggerRefetch(true);
      }
    } else {
      setCreateCollectionErrorMessage("Please fill in all the fields");
    }
  };

  const closeCollectionModal = () => {
    setShowCreateCollection(false);
    setNewCollectionLogo(undefined);
    setNewCollectionName("");
    setNewCollectionDesc("");
    setNewCollectionSymbol("");
    setNewCollectionUrl("");
    setCreateCollectionErrorMessage("");
  };

  const onRemoveFromSale = async (nft) => {
    setTriggerRefetch(false);
    await nftService.editNFT(nft._id, {
      ...nft,
      putOnMarket: false,
    });
    setTriggerRefetch(true);
  };

  const onEditNFTClick = (nft) => {
    setToEditNft(nft);
    setToEditPrice(nft.price || "");
    setToEditMinBid(nft.minBid || "");
    setToEditStartingDate(nft.startingDate || "");
    setToEditEndingDate(nft.endDate || "");
    setShowEditNFTModal(true);
  };

  const onPutOnSale = async (nft) => {
    setTriggerRefetch(false);
    switch (toEditMethod) {
      case "Time Auctions": {
        await nftService.editNFT(nft._id, {
          ...nft,
          minBid: toEditMinBid,
          currentBid: toEditMinBid,
          bidderAddress: user.address,
          startingDate: toEditStartingDate,
          endingDate: toEditEndingDate,
          method: toEditMethod,
          putOnMarket: true,
        });
        break;
      }
      case "Open For Bids": {
        await nftService.editNFT(nft._id, {
          ...nft,
          minBid: toEditMinBid,
          currentBid: toEditMinBid,
          bidderAddress: user.address,
          startingDate: new Date().toISOString().split("T")[0],
          endingDate: toEditEndingDate,
          method: "Time Auctions",
          putOnMarket: true,
        });
        break;
      }
      case "Fixed Price":
        await nftService.editNFT(nft._id, {
          ...nft,
          price: toEditPrice,
          method: toEditMethod,
          putOnMarket: true,
        });
        break;
      default: {
        break;
      }
    }
    setTriggerRefetch(true);
  };

  const onBurn = async (nft) => {
    setTriggerRefetch(false);
    await nftService.burnNFT(nft._id);
    setTriggerRefetch(true);
  };

  const FileContent = ({ nft }) =>
    !nft.isFileVideo ? (
      <img src={nft.file} alt="Axies" />
    ) : (
      <ReactPlayer height="200px" width="100%" controls playing loop url={nft.file} />
    );

  const NFTItem = ({ nft, type }) => (
    <div
      key={nft._id}
      className="slider-item"
      style={{ width: "350px", marginRight: "30px" }}
    >
      <div className="sc-card-product menu_card">
        <div className="meta-info style">
          <div className="author">
            <div className="avatar">
              <img src={nft.collectionDetails.logo} alt="Axies" />
            </div>
            <div className="info">
              <span>Collection</span>
              <h6>
                {" "}
                <Link to={`/collection/${nft.collectionDetails._id}`}>
                  {nft.collectionDetails.name}
                </Link>{" "}
              </h6>
            </div>
          </div>
          {nft.isMinted === false && (
            user.address === userDetails.address && (
              <div className="menu_card">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <i className="fas fa-ellipsis-h"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ margin: 0 }}>
                    {/* {type === "Owned" ? (
                      <Dropdown.Item onClick={() => onEditNFTClick(nft)}>
                        Put On Sale
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item onClick={() => onRemoveFromSale(nft)}>
                        Remove From Sale
                      </Dropdown.Item>
                    )} */}
                    <Dropdown.Item onClick={() => onBurn(nft)}>
                      Burn Item
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )
          )}
        </div>
        <div className="card-media">
          <Link to={`/item-details/${nft._id}`}>
            <FileContent nft={nft} />
          </Link>
          {nft.startingDate && (
            <div className="featured-countdown">
              <span className="slogan"></span>
              <Countdown date={new Date(nft.startingDate).getTime()}>
                {Date.now() > new Date(nft.endingDate).getTime() ? (
                  <span>Auction Item Expired</span>
                ) : (
                  <span>You are good to go!</span>
                )}
              </Countdown>
            </div>
          )}
        </div>
        <div className="card-title">
          <h5>
            <Link to={`/item-details/${nft._id}`}>"{nft.title} "</Link>
          </h5>
        </div>
        <div className="meta-info">
          <div className="author">
            <div className="avatar">
              <img src={nft.creatorDetails?.profileImage || defaultUserProfileImage} alt="Axies" />
            </div>
            <div className="info">
              <span>Creator</span>
              <h6>
                {" "}
                <Link to={`/profile/${nft.creatorDetails?.address}`}>
                  {nft?.creatorDetails?.displayName ||
                    `${nft.creator?.toLowerCase().slice(
                      0,
                      15
                    )}...`}
                </Link>{" "}
              </h6>
            </div>
          </div>
          <div className="price">
            {nft.startingDate ? (
              <>
                <span>Current Bid</span>
                <h5>{nft?.currentBid} ETH</h5>
              </>
            ) : (
              <>
                <span>Price</span>
                <h5>{nft.price} ETH</h5>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const CollectionItem = ({ collection }) => (
    <div
      key={collection._id}
      className="slider-item"
      style={{ width: "330px", marginRight: "30px" }}
    >
      <div className="sc-card-product menu_card">
        <div className="card-media">
          <Link to={`/collection/${collection._id}`}>
            <img src={collection.logo} alt="Axies" />
          </Link>
        </div>
        <div className="card-title mg-bt-16">
          <h5>
            <Link to={`/collection/${collection._id}`}>
              "{collection.name} "
            </Link>
          </h5>
        </div>
        <div className="meta-info">
          <div className="author">
            <div className="avatar">
              <img src={userDetails?.profileImage || defaultUserProfileImage} alt="Axies" />
            </div>
            <div className="info">
              <span>Creator</span>
              <h6>
                <Link to={`/profile/${user.address}`}>
                  {userDetails?.displayName ||
                    `${user?.address.slice(
                      0,
                      15
                    )}...`}
                </Link>{" "}
              </h6>
            </div>
          </div>
          <div className="price">
            <h5>
              {collection.nfts.length}{" "}
              {collection.nfts.length === 1 ? "NFT" : "NFTs"}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <StyledContainer>
      <StyledBox sx={{ width: "100%" }}>
        <Tabs
          className="tabs"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            key={1}
            label={`On sale ${onSaleCount > 0 ? `(${onSaleCount})` : ""}`}
            className="tab"
          />
          <Tab
            key={2}
            label={`Minted ${ownedCount > 0 ? `(${ownedCount})` : ""}`}
            className="tab"
          />
          <Tab
            key={3}
            label={`Not Minted ${createdCount > 0 ? `(${createdCount})` : ""}`}
            className="tab"
          />
          <Tab
            key={4}
            label={`Collections ${collectionCount > 0 ? `(${collectionCount})` : ""
              }`}
            className="tab"
          />
          <Tab key={5} label="Activity" className="tab" />
          {user.address === userDetails.address && (
            <Tab
              key={6}
              label={`Messages (${userMessages?.length})`}
              className="tab"
            />
          )}
        </Tabs>
      </StyledBox>

      <TabPanel value={value} index={0}>
        {onSaleCount === undefined && <p>Loading...</p>}
        {onSaleCount > 0 ? (
          <div className="item-container">
            {nftsOnSale.map((nft) => (
              <NFTItem key={nft._id} nft={nft} type="On Sale" />
            ))}
          </div>
        ) : (
          onSaleCount === 0 && (
            <NotFoundStyledContainer>
              <h4>No items found</h4>
              <p>
                Come back soon! Or try to browse something for you on our
                marketplace
              </p>
              <button onClick={navigateToHome}>Browse marketplace</button>
            </NotFoundStyledContainer>
          )
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {ownedCount === undefined && <p>Loading...</p>}
        {ownedCount > 0 ? (
          <div className="item-container">
            {ownedNfts.map((nft) => (
              <NFTItem key={nft._id} nft={nft} type="Owned" />
            ))}
          </div>
        ) : (
          ownedCount === 0 && (
            <NotFoundStyledContainer>
              <h4>No items found</h4>
              <p>
                Come back soon! Or try to browse something for you on our
                marketplace
              </p>
              <button onClick={navigateToHome}>Browse marketplace</button>
            </NotFoundStyledContainer>
          )
        )}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {createdCount === undefined && <p>Loading...</p>}
        {createdCount > 0 ? (
          <div className="item-container">
            {createdNfts.map((nft) => (
              <NFTItem key={nft._id} nft={nft} type="Created" />
            ))}
          </div>
        ) : (
          createdCount === 0 && (
            <NotFoundStyledContainer>
              <h4>No items found</h4>
              <p>
                Come back soon! Or try to browse something for you on our
                marketplace
              </p>
              <button onClick={navigateToHome}>Browse marketplace</button>
            </NotFoundStyledContainer>
          )
        )}
      </TabPanel>

      <TabPanel value={value} index={3}>
        {collectionCount === undefined && <p>Loading...</p>}
        {collectionCount > 0 ? (
          <>
            <div className="item-container">
              {collections.map((collection) => (
                <CollectionItem key={collection._id} collection={collection} />
              ))}
            </div>
            <NotFoundStyledContainer>
              <button
                onClick={() => setShowCreateCollection(!showCreateCollection)}
              >
                Create new collection
              </button>
            </NotFoundStyledContainer>
          </>
        ) : (
          collectionCount === 0 && (
            <NotFoundStyledContainer>
              <h4>No collections found</h4>
              <button
                onClick={() => setShowCreateCollection(!showCreateCollection)}
              >
                Create new collection
              </button>
            </NotFoundStyledContainer>
          )
        )}
      </TabPanel>

      <TabPanel value={value} index={4}>
        {userActivities === undefined && <p>Loading...</p>}
        {userActivities && userActivities.length === 0 && (
          <p>You have no activities!</p>
        )}
        {userActivities &&
          userActivities.length > 0 &&
          userActivities.map((activity) => (
            <UserActivty key={activity._id} activity={
              {
                img: activity.imageUrl,
                title: activity.title,
                description: activity.description,
                type: activity.type,
                icon: activity.type === ActivityType.NftBidding ? activityIcon.Nft : activityIcon.Person,
                time: activity.updatedAt
              }
            } />
          ))}
      </TabPanel>

      <TabPanel value={value} index={5}>
        {userMessages === undefined && <p>Loading...</p>}
        {userMessages && userMessages.length === 0 && (
          <p>You have no messages!</p>
        )}
        {userMessages && userMessages.length > 0 && (
          <>
            {userMessages.map((message) => (
              <UserMessage key={message._id} message={message} />
            ))}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to="/contact">
                <button>Send new Message</button>
              </Link>
            </div>
          </>
        )}
      </TabPanel>

      <CreateCollection
        onHide={closeCollectionModal}
        onCreate={handleCreateCollection}
        ipfsClient={client}
        logo={newCollectionLogo}
        setLogo={setNewCollectionLogo}
        show={showCreateCollection}
        name={newCollectionName}
        setName={setNewCollectionName}
        desc={newCollectionDesc}
        setDesc={setNewCollectionDesc}
        symbol={newCollectionSymbol}
        setSymbol={setNewCollectionSymbol}
        url={newCollectionUrl}
        setUrl={setNewCollectionUrl}
        error={createCollectionErrorMessage}
      />

      {/* <EditNFTModal
        show={showEditNFTModal}
        onHide={() => setShowEditNFTModal(false)}
        method={toEditMethod}
        setMethod={setToEditMethod}
        price={toEditPrice}
        setPrice={setToEditPrice}
        minBid={toEditMinBid}
        setMinBid={setToEditMinBid}
        startingDate={toEditStartingDate}
        setStartingDate={setToEditStartingDate}
        endingDate={toEditEndingDate}
        setEndingDate={setToEditEndingDate}
        putOnSale={() => onPutOnSale(toEditNft)}
      /> */}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .item-container {
    display: flex;
    flex-wrap: wrap;
  }

  /* Temporary bg color to highlight item background */
  background-color: #222;
`;

const NotFoundStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  h4 {
    padding: 1rem;
  }

  p {
    color: grey;
    padding: 1rem;
    font-size: 1.6rem;
  }

  button {
    margin: 1rem;
    padding: 1.5rem 2rem;

    &:hover {
      color: #fff;
      background-color: #4032ff;
    }
  }
`;

const StyledBox = styled(Box)`
  .tabs {
    border-bottom: 1px solid #4f4f4f;
    display: grid;
    place-items: center;
  }
  .tab {
    color: white;
    font-size: 1.5rem;
    border: none;
    font-weight: bold;
    text-transform: Capitalize;
  }
  .MuiTabs-indicator {
    background-color: white !important;
  }
`;
